import { French } from "flatpickr/dist/l10n/fr";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import MetaTags from "react-meta-tags";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import { ROLE_OPTIONS } from "utils/constants";
import { getOneUtilisateur, saveUtilisateur } from "utils/services";
import * as Yup from "yup";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const TextInfo = props => {
  return (
    <div>
      <div>
        <label htmlFor={props.id} className="">
          {props.icon} {props.label}
        </label>
      </div>
      <div>{props.value}</div>
    </div>
  );
};

TextInfo.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
};

const UtilisateursView = () => {
  const params = useParams();
  const history = useHistory();

  const [utilisateur, setUtilisateur] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (params.id) {
      getOneUtilisateur(
        params.id,
        {},
        success => {
          setUtilisateur(success);
        },
        error => {
          console.error("Erreur", error);
        }
      );
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {(!!utilisateur && utilisateur.prenom + " " + utilisateur.nom) ||
              ""}{" "}
            | Utilisateurs | Admin
          </title>
        </MetaTags>
        <Container fluid={true}>
          <p>
            <Link to="/utilisateurs">{"< Retour"}</Link>
          </p>
          <Row>
            <Col xs="6" sm="6" md="6">
              <small className="text-muted">UTILISATEUR</small>
              <Breadcrumbs
                title={
                  !!utilisateur && utilisateur.prenom + " " + utilisateur.nom
                }
              />
            </Col>{" "}
            <Col xs="6" sm="6" md="6" className="text-end mb-2">
              <Link to={`/utilisateurs/edit/${utilisateur.id}`}>
                <Button color="primary" className="font-16  btn btn-primary">
                  <i className="mdi mdi-pencil" id="viewtooltip"></i>
                  Modifier
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Informations</CardTitle>
                  {errorMsg != "" && (
                    <Row className="mb-3">
                      <Alert color="danger" role="alert">
                        {errorMsg}
                      </Alert>
                    </Row>
                  )}
                  <Row className="mb-3">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <TextInfo
                        label="Nom"
                        id="nom-utilisateur"
                        name="nom"
                        value={utilisateur.nom}
                      />
                    </div>
                    <div className="col-md-6  col-sm-6 col-xs-6">
                      <TextInfo
                        label="Prénom"
                        id="prenom-utilisateur"
                        name="prenom"
                        value={utilisateur.prenom}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <TextInfo
                        label="Nom d'utilisateur"
                        id="username-utilisateur"
                        name="username"
                        value={utilisateur.username}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <TextInfo
                        label="Role"
                        id="role-utilisateur"
                        name="role"
                        value={
                          ROLE_OPTIONS.find(e => e.value == utilisateur.role)
                            ?.label
                        }
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <TextInfo
                        label="Email"
                        id="email-utilisateur"
                        name="email"
                        value={utilisateur.email}
                        type="email"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <TextInfo
                        label="Telephone"
                        id="telephone-utilisateur"
                        name="telephone"
                        value={utilisateur.telephone}
                      />
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Card>
              <CardBody>
                <Row className="buttons-container">
                  <Col
                    sm={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      color="light"
                      size="lg"
                      style={{ width: "50%" }}
                      onClick={history.goBack}
                    >
                      Retour
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UtilisateursView;
