import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
  Form,
  Button,
  InputGroup,
  Dropdown,
} from "reactstrap";
import Select from "react-select";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PropTypes from "prop-types";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import {
  CATEGORIES_ACTIVITE_OPTIONS,
  CATEGORIES_AGE_OPTIONS,
} from "utils/constants";
import { saveActivite, getOneActivite, getUtilisateurs } from "utils/services";
import dayjs from "dayjs";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const TextField = props => {
  return (
    <>
      <label htmlFor={props.id} className="">
        {props.label}
      </label>
      {props.type == "date" ? (
        <InputGroup>
          <Flatpickr
            className="form-control d-block"
            placeholder="DD/MM/YYYY"
            name={props.name}
            onChange={([date]) => {
              props.formik.setFieldValue(
                props.name,
                dayjs(date).format("YYYY-MM-DD")
              );
            }}
            value={
              props.formik.values[props.name] || ""
                ? dayjs(props.formik.values[props.name], "YYYY-MM-DD").format(
                    "DD/MM/YYYY"
                  )
                : ""
            }
            options={{
              locale: "French",
              altFormat: "d/m/Y",
              dateFormat: "d/m/Y",
            }}
          />
        </InputGroup>
      ) : (
        <Input
          id={props.id}
          className="form-control"
          name={props.name}
          type={props.type || "text"}
          onChange={props.formik.handleChange}
          onBlur={props.formik.handleBlur}
          placeholder={props.placeholder || ""}
          value={props.formik.values[props.name] || ""}
          required
          invalid={
            props.formik.touched[props.name] && props.formik.errors[props.name]
              ? true
              : false
          }
        />
      )}
      {props.formik.touched[props.name] && props.formik.errors[props.name] ? (
        <FormFeedback type="invalid">
          {props.formik.errors[props.name]}
        </FormFeedback>
      ) : null}
    </>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  formik: PropTypes.object,
};

const ActivitesForm = () => {
  const params = useParams();
  const history = useHistory();

  const [activite, setActivite] = useState({});
  const [animateurs, setAnimateurs] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},

    validationSchema: Yup.object({
      nomActivite: Yup.string().required("Obligatoire"),
      // nbPersonsMax: Yup.number()
      //   .positive("Le nombre est incorrect")
      //   .integer("Le nombre est incorrect")
      //   .typeError("Le nombre est incorrect"),
    }),
    onSubmit: values => {
      values.joursActivite = values.jours?.map(j => {
        return { date: dayjs(j).format("YYYY-MM-DD") };
      });

      values.trancheAge = values.ageActivite?.map(a => a.value).join(",");
      saveActivite(
        values,
        success => {
          console.log("save activite", success);
          // if (history.length > 0) {
          //   history.goBack();
          // } else {
          history.push("/activites");
          // }
        },
        error => {
          console.error(error);
        }
      );
    },
  });

  useEffect(() => {
    if (params.id) {
      getOneActivite(
        params.id,
        success => {
          formik.resetForm();

          success.jours = success.joursActivite
            ? success.joursActivite.map(j => dayjs(j.date).toDate())
            : [];

          const editValues = {
            ...success,
            dateDebut: success.dateDebut
              ? dayjs(success.dateDebut).format("YYYY-MM-DD")
              : "",
            dateFin: success.dateFin
              ? dayjs(success.dateFin).format("YYYY-MM-DD")
              : "",

            animateur: success.animateur
              ? {
                  label: success.animateur.prenom + " " + success.animateur.nom,
                  value: success.animateur.id,
                  id: success.animateur.id,
                }
              : null,
            ageActivite: success.trancheAge
              ? CATEGORIES_AGE_OPTIONS.filter(c =>
                  success.trancheAge.split(",").find(t => c.value == t)
                )
              : [],
          };

          console.log(dayjs(formik.values.dateFin).toDate());
          if (success.avecCalendrier) {
            setShowCalendar(true);
          }

          setIsEdit(true);
          setActivite(success);
          delete editValues.inscriptions;
          formik.setValues(editValues);
        },
        error => {
          console.error("Erreur", error);
        }
      );
    }

    getUtilisateurs(
      success => {
        setAnimateurs(
          success.map(u => {
            return { label: u.prenom + " " + u.nom, value: u.id, id: u.id };
          })
        );
      },
      error => {
        console.error("Erreur", error);
      }
    );
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {(isEdit && activite && activite.nomActivite) ||
              "Nouvelle activité"}{" "}
            | Activités | Admin
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs
            title={
              isEdit ? activite && activite.nomActivite : "Nouvelle activité"
            }
            breadcrumbItem={
              isEdit ? "Modification activité" : "Nouvelle activité"
            }
          />

          <Form>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Informations</CardTitle>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Dénomination de l'actvité"
                          id="nom-activite"
                          name="nomActivite"
                          formik={formik}
                        />
                      </div>
                      <div className="col-md-6">
                        <Label>{"Catégorie"}</Label>
                        <Select
                          value={CATEGORIES_ACTIVITE_OPTIONS.find(
                            e => formik.values.categorieActivite == e.value
                          )}
                          onChange={({ label, value }) =>
                            formik.setFieldValue("categorieActivite", value)
                          }
                          name="categorieActivite"
                          options={CATEGORIES_ACTIVITE_OPTIONS}
                          placeholder="Selectionnez la catégorie"
                          classNamePrefix="select2-categorie"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-12">
                        <TextField
                          label="Description de l'actvité"
                          id="desc-activite"
                          name="description"
                          formik={formik}
                          type="textarea"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Nombre pers. max"
                          id="nb-persons-max"
                          name="nbPersonsMax"
                          formik={formik}
                        />
                      </div>
                      <div className="col-md-6">
                        <Label>{"Tranche d'âge"}</Label>
                        <Select
                          value={formik.values.ageActivite}
                          onChange={e => {
                            formik.setFieldValue("ageActivite", e);
                          }}
                          name="ageActivite"
                          options={CATEGORIES_AGE_OPTIONS}
                          placeholder="Selectionnez la tranche d'âge"
                          classNamePrefix="select2-tranche-age"
                          isMulti={true}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Date de debut"
                          id="date-debut-activite"
                          name="dateDebut"
                          formik={formik}
                          type="date"
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Date de fin"
                          id="date-debut-activite"
                          name="dateFin"
                          formik={formik}
                          type="date"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Tarif"
                          id="tarif"
                          name="tarif"
                          formik={formik}
                        />
                      </div>
                      <div className="col-md-6">
                        <Label>{"Animateur"}</Label>
                        <Select
                          value={formik.values.animateur}
                          onChange={option =>
                            formik.setFieldValue("animateur", option)
                          }
                          name="animateur"
                          options={animateurs}
                          placeholder="Choisir un animateur"
                          classNamePrefix="select2-animateur"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <Input
                          className="form-check-input me-2"
                          id="activite-checkbox-1"
                          type="checkbox"
                          value="1"
                          name="avecCalendrier"
                          onClick={event => {
                            const isChecked = !formik.values.avecCalendrier;
                            formik.setFieldValue("avecCalendrier", isChecked);
                            if (isChecked) {
                              setShowCalendar(true);
                            } else {
                              setShowCalendar(false);
                            }
                          }}
                          checked={formik.values.avecCalendrier}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="activite-checkbox-1"
                        >
                          Selectionner des jours
                        </label>
                      </div>
                    </Row>
                    {showCalendar && (
                      <DayPicker
                        fromDate={dayjs(formik.values.dateDebut).toDate()}
                        toDate={dayjs(formik.values.dateFin).toDate()}
                        initialFocus={true}
                        numberOfMonths="2"
                        mode="multiple"
                        min={1}
                        selected={activite.jours || []}
                        onSelect={days => {
                          formik.setFieldValue("jours", days);
                          activite.jours = [...days];
                          setActivite(activite);
                        }}
                        // footer={footer}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Card>
                <CardBody>
                  <Row className="buttons-container">
                    <Col
                      lg={6}
                      sm={6}
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: 5,
                      }}
                    >
                      <Button
                        color="light"
                        size="lg"
                        style={{ width: "100%" }}
                        onClick={history.goBack}
                      >
                        Annuler
                      </Button>
                    </Col>
                    <Col
                      lg={6}
                      sm={6}
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: 5,
                      }}
                    >
                      <Button
                        color="primary"
                        size="lg"
                        style={{ width: "100%" }}
                        onClick={() => formik.submitForm()}
                      >
                        Valider
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ActivitesForm;
