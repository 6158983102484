// const API_URL = "http://admin.ajla45.fe-dev.fr/api";
// // const API_URL = "http://localhost:9090/api";

const CATEGORIES_ADHESIONS_OPTIONS = [
  { label: "Action Enfance", value: "enfance" },
  { label: "Action Jeunesse", value: "jeunesse" },
  { label: "Adulte", value: "adulte" },
];

const DOCUMENT_TYPE_OPTIONS = [
  { label: "Fiche sanitaire", value: "fiche_sanitaire" },
  { label: "Carnet de santé", value: "carnet_sante" },
  { label: "Assurance", value: "assurance" },
  { label: "Autorisation parentale", value: "autorisation_parentale" },
  { label: "PAI", value: "pai", optional: 1 },
  { label: "Jugement(divorce)", value: "jugement_divorce", optional: 1 },
  { label: "Autre", value: "autre", optional: 1 },
];

const CATEGORIES_AGE_OPTIONS = [
  { label: "6-8 ans", value: "6-8" },
  { label: "8-10 ans", value: "8-10" },
  { label: "11-14 ans", value: "11-14" },
  { label: "15-17 ans", value: "15-17" },
  { label: "Adultes", value: "Adulte" },
];

const CATEGORIES_ACTIVITE_OPTIONS = [
  { label: "Accueil collectif de mineu", value: "accueil_mineur" },
  { label: "Action de prévention", value: "action_prevention" },
  { label: "Activités manuelles", value: "activites_manuelles" },
  { label: "Aide aux devoirs", value: "aide_devoirs" },
  { label: "Séjours", value: "sejours" },
  { label: "Sorties", value: "sorties" },
  { label: "Sport", value: "sport" },
  { label: "Stage", value: "stage" },
  { label: "Autre", value: "autre" },
];

const CLASSE_OPTIONS = [
  { label: "CP", value: "CP" },
  { label: "CE1", value: "CE1" },
  { label: "CE2", value: "CE2" },
  { label: "CM1", value: "CM1" },
  { label: "CM2", value: "CM2" },
  { label: "6ème", value: "6ème" },
  { label: "5ème", value: "5ème" },
  { label: "4ème", value: "4ème" },
  { label: "3ème", value: "3ème" },
  { label: "2de", value: "2de" },
  { label: "1ère", value: "1ère" },
  { label: "Terminale", value: "Terminale" },
];

const ROLE_OPTIONS = [
  { label: "Administrateur", value: "ADMIN" },
  { label: "Utilisateur", value: "UTILISATEUR" },
  // { label: "Animateur", value: "ANIMATEUR" },
];

const LISTE_PRESENCE_STATUS = {
  pending: "En cours",
  validated: "Terminée",
};

const TYPE_PAIEMENT = [
  { label: "Espèces", value: "ESP" },
  { label: "Chèque", value: "CHQ" },
];

export {
  CATEGORIES_ADHESIONS_OPTIONS,
  DOCUMENT_TYPE_OPTIONS,
  CATEGORIES_AGE_OPTIONS,
  CATEGORIES_ACTIVITE_OPTIONS,
  CLASSE_OPTIONS,
  ROLE_OPTIONS,
  // API_URL,
  LISTE_PRESENCE_STATUS,
  TYPE_PAIEMENT,
};
