import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import PropTypes from "prop-types";
import { Link, useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { DOCUMENT_TYPE_OPTIONS } from "utils/constants";
import PDFIcon from "../../../assets/images/pdf-icon.png";

import {
  deleteInscritpion,
  getActivites,
  getAdherents,
  getOneAdherent,
  saveInscription,
} from "utils/services";
import ActivitesSubList from "pages/Activites/Listing/ActivitesSubList";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import SweetAlert from "react-bootstrap-sweetalert";
import dayjs from "dayjs";

const TextInfo = props => {
  return (
    <div>
      <div>
        <label htmlFor={props.id} className="">
          {props.icon} {props.label}
        </label>
      </div>
      <div>{props.value}</div>
    </div>
  );
};

TextInfo.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.object,
};

const AdherentsView = () => {
  const params = useParams();
  const history = useHistory();

  const [adherent, setAdherent] = useState({});
  const [files, setFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalActivites, setModalActivites] = useState(false);
  const [modalInscrire, setModalInscrire] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [displayedFile, setDisplayedFile] = useState(null);
  const [activiteList, setActviteList] = useState([]);
  const [errorInscription, setErrorInscription] = useState("");

  useEffect(() => {
    if (params.id) {
      getAdherent();
    }
  }, []);

  const getAdherent = () => {
    getOneAdherent(
      params.id,
      success => {
        console.log("useEffect", success);
        setAdherent(success);
      },
      error => {
        console.error("Erreur", error);
      }
    );
  };
  const searchActivites = text => {
    setLoading(true);
    const query = {};

    if (text) {
      query.text = text;
    }
    getActivites(
      query,
      success => {
        setActviteList(success);
      },
      err => {}
    );
  };

  const inscrire = activite => {
    toastr.options = {
      timeOut: 2000,
    };
    saveInscription(
      {
        adherents: [{ id: adherent.id }],
        activite,
        jours: activite.selectedDays,
      },
      success => {
        toastr.success("Inscription éffectuée", "");
        setErrorInscription("");
        console.log("inscritpion réussie");
        getAdherent();
      },
      error => {
        if (error.data && error.data.message) {
          setErrorInscription(error.data.message);
        }
      }
    );
  };

  const desinscrire = activite => {
    toastr.options = {
      timeOut: 2000,
    };

    deleteInscritpion(
      activite.inscriptionId,
      {},
      success => {
        toastr.success("Désinscription éffectuée", "");
        getAdherent();
      },
      error => {}
    );
  };

  const toggleModalActivites = () => {
    setModalActivites(!modalActivites);
    setActviteList([]);
  };

  const toggleModalInscrire = () => {
    setModalInscrire(!modalInscrire);
    setActviteList([]);
  };

  const onViewFile = file => {
    setDisplayedFile(file);
    setViewModal(true);
  };

  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {(adherent && adherent.prenom + " " + adherent.nom) || ""} |
            Adhérents | Admin
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <p>
              <Link
                to="#"
                onClick={() => {
                  if (history.length) {
                    history.goBack();
                  } else {
                    window.location = "/adherents";
                  }
                }}
              >
                {"< Retour"}
              </Link>
            </p>
          </Row>
          <Row>
            <Col xs="12" sm="6" md="6">
              <small className="text-muted">ADHÉRENT</small>
              <Breadcrumbs
                title={adherent && adherent.nom + " " + adherent.prenom}
              />
              <small className="text-muted">
                {adherent && adherent.createdBy ? (
                  <span>
                    {"ajouté par " +
                      adherent.createdBy.prenom +
                      " - " +
                      dayjs(adherent.createdAt).format("DD/MM/YYYY HH:mm")}{" "}
                    <br />
                  </span>
                ) : (
                  ""
                )}
                {adherent && adherent.modifiedBy
                  ? "modifié par " +
                    adherent.modifiedBy.prenom +
                    " - " +
                    dayjs(adherent.modifiedAt).format("DD/MM/YYYY HH:mm")
                  : ""}
              </small>
            </Col>
            <Col xs="12" sm="6" md="6" className="text-sm-end mb-2">
              <Button
                color="info"
                className="font-16 btn btn-primary me-3"
                onClick={() => toggleModalInscrire()}
              >
                <i className="mdi mdi-clipboard-edit-outline act-icon" />{" "}
                Inscrire à une activité
              </Button>
              {adherent.inscriptions?.length > 0 && (
                <Button
                  color="info"
                  className="font-16 btn btn-primary me-3"
                  onClick={() => toggleModalActivites()}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Voir les activités
                </Button>
              )}
              <Link to={`/adherents/edit/${adherent.id}`}>
                <Button color="primary" className="font-16  btn btn-primary">
                  <i className="mdi mdi-pencil" id="viewtooltip"></i>
                  Modifier
                </Button>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col lg="6" md="12" sm="12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Informations</CardTitle>
                  <Row className="mb-3">
                    <div className="btn-group col-md-6" role="group">
                      {adherent.type}
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-6">
                      <TextInfo
                        label="Nom"
                        id="nom-adherent"
                        name="nom"
                        value={adherent.nom}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextInfo
                        label="Prénom"
                        id="prenom-adherent"
                        name="prenom"
                        value={adherent.prenom}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-6">
                      <TextInfo
                        label="Date de naissance"
                        id="date-naissance-adherent"
                        name="dateNaissance"
                        type="date"
                        value={
                          adherent.dateNaissance
                            ? dayjs(adherent.dateNaissance).format("DD/MM/YYYY")
                            : ""
                        }
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-12">
                      <TextInfo
                        label="Adresse"
                        id="adresse-adherent"
                        name="adresse"
                        value={adherent.adresse}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-6">
                      <TextInfo
                        label="Ville"
                        id="ville-adherent"
                        name="ville"
                        value={adherent.ville}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextInfo
                        label="Code postal"
                        id="code-postal-adherent"
                        name="codePostal"
                        value={adherent.codePostal}
                      />
                    </div>
                  </Row>
                  {adherent.type == "jeunesse" && (
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextInfo
                          label="Etablissement scolaire"
                          id="etablissement-adherent"
                          name="etablissementScolaire"
                          value={adherent.etablissementScolaire}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInfo
                          label="Classe"
                          id="etablissement-adherent"
                          name="etablissementScolaire"
                          value={adherent.classe}
                        />
                      </div>
                    </Row>
                  )}
                  <Row className="mb-3">
                    {adherent.type == "adulte" && (
                      <div className="col-md-6">
                        <TextInfo
                          label="Profession"
                          id="profession-adherent"
                          name="profession"
                          value={adherent.profession}
                        />
                      </div>
                    )}
                    <div className="col-md-6">
                      <TextInfo
                        label="Numéro Allocataire CAF"
                        id="numcaf-adherent"
                        name="numCAF"
                        value={adherent.numCAF}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-6">
                      <TextInfo
                        label="Email"
                        id="email-adherent"
                        name="email"
                        type="email"
                        value={adherent.email}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-6">
                      <TextInfo
                        label="Telephone"
                        id="telephone-adherent"
                        name="telephone"
                        value={adherent.telephone}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextInfo
                        label="Mobile"
                        id="mobile-adherent"
                        name="mobile"
                        value={adherent.mobile}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-12">
                      <TextInfo
                        label="Personne à contacter en cas d'urgence"
                        id="urgence-adherent"
                        name="contactUrgence"
                        value={adherent.contactUrgence}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-6">
                      <TextInfo
                        label="Telephone Urgence"
                        id="urgence-telephone-adherent"
                        name="telephoneUrgence"
                        value={adherent.telephoneUrgence}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextInfo
                        label="Mobile Urgence"
                        id="urgence-mobile-adherent"
                        name="mobileUrgence"
                        value={adherent.mobileUrgence}
                      />
                    </div>
                  </Row>
                  {/* {adherent.inscriptions?.length > 0 && (
                    <Row>
                      <h5>Actvités</h5>
                      <Row>
                        {adherent.inscriptions.map((elt, index) => (
                          <span key={index}>
                            {elt.activite.nomActivite}{" "}
                            <i className="mdi mdi-delete act-icon"></i>
                          </span>
                        ))}
                      </Row>
                    </Row>
                  )} */}
                </CardBody>
              </Card>
            </Col>
            {/* </Row> */}

            <Col lg="6" md="12" sm="12">
              {adherent.type != "adulte" && (
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">
                          Responsable légal 1
                        </CardTitle>
                        <Row className="mb-3">
                          <div className="col-md-6">
                            <TextInfo
                              label="Nom"
                              id="legal-nom"
                              name="legalNom"
                              value={adherent.famille?.nomLegal}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInfo
                              label="Prénom"
                              id="legal-prenom"
                              name="legalPrenom"
                              value={adherent.famille?.prenomLegal}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col-md-6">
                            <TextInfo
                              label="Telephone"
                              id="telephone-legal"
                              name="legalTelephone"
                              value={adherent.famille?.telephoneLegal}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInfo
                              label="Mobile"
                              id="mobile-legal"
                              name="legalMobile"
                              value={adherent.famille?.mobileLegal}
                            />
                          </div>
                        </Row>
                        <CardTitle className="h4">
                          Responsable légal 2
                        </CardTitle>
                        <Row className="mb-3">
                          <div className="col-md-6">
                            <TextInfo
                              label="Nom"
                              id="legal2-nom"
                              name="legal2Nom"
                              value={adherent.famille?.nomLegal2}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInfo
                              label="Prénom"
                              id="legal2-prenom"
                              name="legal2Prenom"
                              value={adherent.famille?.prenomLegal2}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col-md-6">
                            <TextInfo
                              label="Telephone"
                              id="telephone-legal2"
                              name="legal2Telephone"
                              value={adherent.famille?.telephoneLegal2}
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInfo
                              label="Mobile"
                              id="mobile-legal2"
                              name="legal2Mobile"
                              value={adherent.famille?.mobileLegal2}
                            />
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Assurances</CardTitle>
                      <div>
                        <Row className="mb-3">
                          <Col lg={6}>
                            <TextInfo
                              label="Assureur"
                              id="nom-assurance"
                              name="assurance[nom]"
                              value={adherent.assurance?.nom}
                            />
                          </Col>
                          <Col lg={6}>
                            <TextInfo
                              label="Numéro de Contrat"
                              id="contrat-assurance"
                              name="assurance[numContrat]"
                              value={adherent.assurance?.numContrat}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={6}>
                            <TextInfo
                              label="Numéro de Contrat"
                              id="adresse-assurance"
                              name="assurance[adresse]"
                              value={adherent.assurance?.adresse}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={6}>
                            <TextInfo
                              label="Ville"
                              id="ville-assurance"
                              name="assurance[ville]"
                              value={adherent.assurance?.nom}
                            />
                          </Col>
                          <Col lg={6}>
                            <TextInfo
                              label="Code postal"
                              id="code-postal-assurance"
                              name="assurance[codePostal]"
                              value={adherent.assurance?.codePostal}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={6}>
                            <TextInfo
                              label="Téléphone"
                              id="telephone-assurance"
                              name="assurance[telephone]"
                              value={adherent.assurance?.telephone}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col lg={12}>
                            <TextInfo
                              label="Responsabilité civile"
                              id="assurance-checkbox-1"
                              icon={
                                adherent.assurance?.responsabiliteCivile ? (
                                  <i className="bx bx-comment-minus text-success"></i>
                                ) : (
                                  <i className="mdi mdi-close-thick text-danger"></i>
                                )
                              }
                            />

                            <TextInfo
                              label="Responsabilité individuelle"
                              id="assurance-checkbox-2"
                              icon={
                                adherent.assurance
                                  ?.responsabiliteIndividuelle ? (
                                  <i className="bx bx-comment-minus text-success"></i>
                                ) : (
                                  <i className="mdi mdi-close-thick text-danger"></i>
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Autorisations</CardTitle>

                  <Row className="mb-3">
                    <Col lg={12}>
                      <TextInfo
                        label={`Autorise l'équipe à prendre, le cas échéant,
                          toutes mesures (traitement médical, hostpitalisation,
                          intervention chirugicale ...) rendues nécessaires ${
                            adherent.type == "jeunesse"
                              ? "par l'état des santé de mes enfants"
                              : ""
                          }`}
                        id="autorisations-checkbox-1"
                        icon={
                          adherent.autorisationMedicale ? (
                            <i className="bx bx-comment-minus text-success"></i>
                          ) : (
                            <i className="mdi mdi-close-thick text-danger"></i>
                          )
                        }
                      />

                      {adherent.type == "jeunesse" && (
                        <>
                          <TextInfo
                            label="Autorise l'équipe à transporter ou faire
                        transporter mon enfant, puor d'éventuelles
                        sorties, par d'autres parents, les animateurs,
                        en transport en commun ou en car"
                            id="autorisations-checkbox-3"
                            icon={
                              adherent.autorisationTransport ? (
                                <i className="bx bx-comment-minus text-success"></i>
                              ) : (
                                <i className="mdi mdi-close-thick text-danger"></i>
                              )
                            }
                          />
                          <TextInfo
                            label="Autorise mon enfant à rentrer seul(e) le soir
                              après l'activité"
                            id="autorisations-checkbox-4"
                            icon={
                              adherent.autorisationSortie ? (
                                <i className="bx bx-comment-minus text-success"></i>
                              ) : (
                                <i className="mdi mdi-close-thick text-danger"></i>
                              )
                            }
                          />
                          <TextInfo
                            label="Autorise mon enfant à se baigner"
                            id="autorisations-checkbox-5"
                            icon={
                              adherent.autorisationBaignade ? (
                                <i className="bx bx-comment-minus text-success"></i>
                              ) : (
                                <i className="mdi mdi-close-thick text-danger"></i>
                              )
                            }
                          />
                          <TextInfo
                            label="Autorise mon enfant à être photographié"
                            id="autorisations-checkbox-6"
                            icon={
                              adherent.autorisationPhoto ? (
                                <i className="bx bx-comment-minus text-success"></i>
                              ) : (
                                <i className="mdi mdi-close-thick text-danger"></i>
                              )
                            }
                          />
                        </>
                      )}

                      <TextInfo
                        label="Autorise le centre à utiliser les photos pour les
                              supports de communication"
                        id="autorisations-checkbox-2"
                        icon={
                          adherent.autorisationCommunication ? (
                            <i className="bx bx-comment-minus text-success"></i>
                          ) : (
                            <i className="mdi mdi-close-thick text-danger"></i>
                          )
                        }
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Documents</CardTitle>

                  <Row>
                    {adherent.fichiers?.length ? (
                      adherent.fichiers
                        .filter(f => !deletedFiles.find(df => df == f.fileName))
                        .map((fichier, index) => (
                          <Col lg={4} md={4} sm={6} key={index}>
                            <div className="mt-4 document-item">
                              {/* <div style={{ flex: 2 }}> */}
                              {/* <div className="document-preview">
                                {fichier.contentType == "application/pdf" ? (
                                  // <img src={PDFIcon} />
                                  <div className="avatar-sm">
                                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                      <i className="bx bxs-file-pdf" />
                                    </span>
                                  </div>
                                ) : (
                                  // <img
                                  //   src={
                                  //     "data:image/png;base64," +
                                  //     fichier.file.data
                                  //   }
                                  // />
                                  <div className="avatar-sm">
                                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                      <i className="bx bxs-file-image" />
                                    </span>
                                  </div>
                                )}
                              </div> */}
                              <div className="document-name">
                                {fichier.fileType != "undefined"
                                  ? DOCUMENT_TYPE_OPTIONS.find(
                                      dt => dt.value == fichier.fileType
                                    )?.label
                                  : "Type non défini"}
                              </div>
                              <div
                                className="document-button"
                                style={{ justifySelf: "flex-end" }}
                              >
                                <Button
                                  color="info"
                                  outline
                                  onClick={() => onViewFile(fichier)}
                                  size="sm"
                                >
                                  <i className="mdi mdi-eye font-size-18"></i>
                                </Button>
                              </div>
                            </div>
                          </Col>
                        ))
                    ) : (
                      <p>Aucun document</p>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {modalActivites && (
            <Modal isOpen={modalActivites} toggle={toggleModalActivites}>
              <ModalHeader>
                <h5 className="modal-title">Activités</h5>
                <button
                  type="button"
                  onClick={() => {
                    toggleModalActivites();
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {/* <button
                  type="button"
                  onClick={() => {
                    toggleModalActivites();
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </ModalHeader>
              <ModalBody>
                {/* {adherent.inscriptions.map((elt, index) => ( */}
                <ActivitesSubList
                  activites={adherent.inscriptions?.map((elt, index) => {
                    elt.activite.inscriptionId = elt.id;
                    elt.activite.selectedDays = elt.jours;
                    return elt.activite;
                  })}
                  adherentActivites={
                    adherent.inscriptions
                      ? adherent.inscriptions.map(elt => elt.activite)
                      : []
                  }
                  onDesincrire={desinscrire}
                  onValidate={inscrire}
                />
                {/* // <span key={index}>
                //   {elt.activite.nomActivite}{" "}
                //   <i className="mdi mdi-delete act-icon"></i>
                // </span>
              // ))} */}
              </ModalBody>
            </Modal>
          )}
          {modalInscrire && (
            <Modal isOpen={modalInscrire} toggle={toggleModalInscrire}>
              <ModalHeader>
                <h5 className="modal-title">Activités</h5>
                <button
                  type="button"
                  onClick={() => {
                    toggleModalInscrire();
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody>
                {/* {adherent.inscriptions.map((elt, index) => ( */}
                <ActivitesSubList
                  activites={activiteList}
                  searchActivites={searchActivites}
                  adherentActivites={
                    adherent.inscriptions
                      ? adherent.inscriptions.map(elt => elt.activite)
                      : []
                  }
                  onValidate={inscrire}
                  subscribe
                  search
                  error={errorInscription}
                />
                {/* // <span key={index}>
                //   {elt.activite.nomActivite}{" "}
                //   <i className="mdi mdi-delete act-icon"></i>
                // </span>
              // ))} */}
              </ModalBody>
            </Modal>
          )}

          {!!displayedFile && (
            <Modal
              size="xl"
              id="view-file-modal"
              isOpen={viewModal && !!displayedFile}
              toggle={toggleViewModal}
            >
              <ModalBody>
                {displayedFile.contentType == "application/pdf" ? (
                  <iframe
                    width="100%"
                    height="100%"
                    src={
                      "data:application/pdf;base64, " +
                      encodeURI(displayedFile.file.data)
                    }
                  ></iframe>
                ) : (
                  <img
                    src={"data:image/png;base64," + displayedFile.file.data}
                  />
                )}
              </ModalBody>
            </Modal>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdherentsView;
