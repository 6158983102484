import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Link,
  useRouteMatch,
  withRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import dayjs from "dayjs";
import {
  getActivites,
  getAdherents,
  getFamilles,
  saveInscription,
} from "../../../utils/services";
import ActivitesSubList from "pages/Activites/Listing/ActivitesSubList";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { MetaTags } from "react-meta-tags";
import CardFamille from "../card-famille";
import { filter, map } from "lodash";
import Select from "react-select";
import {
  CATEGORIES_ADHESIONS_OPTIONS,
  CATEGORIES_AGE_OPTIONS,
} from "utils/constants";

const AdherentsList = props => {
  let match = useRouteMatch();
  const { search } = useLocation();

  const [adherents, setAdherents] = useState([]);
  const [familles, setFamilles] = useState([]);
  const [activiteList, setActviteList] = useState([]);
  const [view, setView] = useState("adherents");
  // const [text, setText] = useState("");
  const [filters, setFilters] = useState({ text: "", type: [] });
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [toggleDropdownActions, setToggleDropdownActions] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [errorInscription, setErrorInscription] = useState("");
  const history = useHistory();

  const sizePerPage = 10;

  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: adherents.length, // replace later with size(users),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "checkbox",
    hideSelectAll: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedRows([...selectedRows, { id: row.id }]);
      } else {
        setSelectedRows(selectedRows.filter(x => x.id !== row.id));
      }
      console.log(selectedRows);
    },
  };

  const listColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "Nom",
      dataField: "nom",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, adh) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {adh.prenom} {adh.nom}
              <br />
              <small className="text-muted">{adh.type}</small>
            </Link>
          </h5>
          {/* <p className="text-muted mb-0">{adh.prenom}</p> */}
        </>
      ),
    },
    {
      dataField: "email",
      text: "Contact",
      sort: true,
      formatter: (cellContent, adh) => (
        <>
          <span className="font-size-14 mb-1">{adh.email}</span>
          <br />
          <span className="font-size-14 mb-1">{adh.telephone}</span>
          {/* <p className="text-muted mb-0">{adh.prenom}</p> */}
        </>
      ),
    },
    {
      text: "Age",
      dataField: "dateNaissance",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, adh) => (
        <>
          {adh.dateNaissance
            ? dayjs().diff(dayjs(adh.dateNaissance), "year")
            : ""}
        </>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, adh) => (
        <div>
          {adh.errors.length > 0 && (
            <div>
              <span className="text-warning text-bold">
                {" "}
                <i className="mdi mdi-alert-outline font-size-18" />{" "}
                Informations manquantes
              </span>
            </div>
          )}
          <div className="d-flex gap-3">
            <Link className="text-primary" to={"/adherents/view/" + adh.id}>
              <Button className="btn-sm" color="info" outline>
                <i className="mdi mdi-eye font-size-18" id="edittooltip"></i>
              </Button>
            </Link>
            <Link className="text-success" to={"/adherents/edit/" + adh.id}>
              <Button className="btn-sm" color="danger" outline>
                <i className="mdi mdi-pencil font-size-18" id="viewtooltip"></i>
              </Button>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const query = new URLSearchParams(search);

    if (query.get("vue") == "familles") {
      setView("familles");
      searchFamilles();
    } else {
      searchAdherent();
    }
  }, []);

  const searchAdherent = () => {
    setLoading(true);
    // const query = {};

    // if (text) {
    //   query.text = text;
    // }
    console.log(filters);
    getAdherents(
      {
        ...filters,
        type: filters.type.map(t => t.value).join(","),
        age: filters.age?.map(t => t.value).join(","),
      },
      success => {
        setLoading(false);
        setAdherents(success);
      },
      err => {}
    );
  };

  const searchFamilles = text => {
    setLoading(true);
    const query = {};

    if (text) {
      query.search = text;
    }
    getFamilles(
      query,
      success => {
        setLoading(false);
        setFamilles(success);
      },
      err => {}
    );
  };

  const searchActivites = text => {
    setLoading(true);
    const query = {};

    if (text) {
      query.text = text;
    }
    getActivites(
      query,
      success => {
        setActviteList(success);
      },
      err => {}
    );
  };

  const inscrire = activite => {
    toastr.options = {
      timeOut: 2000,
    };
    if (selectedRows.length) {
      saveInscription(
        {
          adherents: selectedRows,
          activite,
          jours: activite.selectedDays.map(j => j.date),
        },
        success => {
          toastr.success("Inscription éffectuée", "");
          console.log("inscritpion réussie");
        },
        error => {
          if (error.message) {
            setErrorInscription(error.message);
          }
        }
      );
    }
  };

  const toggleModalActivites = () => {
    setModal(!modal);
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  const changeView = nView => {
    if (nView != view) {
      setView(nView);
      removeFilters();
      history.push("/adherents?vue=" + nView);

      if (nView == "adherents") {
        searchAdherent();
      } else {
        searchFamilles();
      }
    }
  };
  const removeFilters = () => {
    setFilters({ type: [], text: "" });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Adhérents | Admin</title>
        </MetaTags>
        <Container fluid>
          <Modal isOpen={modal} toggle={toggleModalActivites}>
            <ModalHeader>
              <h5 className="modal-title">Activités</h5>
              <button
                type="button"
                onClick={() => {
                  toggleModalActivites();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody>
              {/* {adherent.inscriptions.map((elt, index) => ( */}
              <ActivitesSubList
                activites={activiteList}
                searchActivites={searchActivites}
                onClickButton={inscrire}
                adherentActivites={[]}
                onValidate={inscrire}
                subscribe
                search
                error={errorInscription}
              />
              {/* // <span key={index}>
                //   {elt.activite.nomActivite}{" "}
                //   <i className="mdi mdi-delete act-icon"></i>
                // </span>
              // ))} */}
            </ModalBody>
          </Modal>

          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Adhésion" breadcrumbItem="User List" /> */}
          <h1 className="mb-3">
            {view == "familles" ? "Liste des familles" : "Liste des adhérents"}
          </h1>
          <Row>
            <Col lg="12">
              <Row>
                <div
                  className="btn-group col-lg-6 col-md-6 col-sm-12 mb-2"
                  role="group"
                >
                  <Button
                    color="primary"
                    outline={view != "adherents"}
                    onClick={() => changeView("adherents")}
                  >
                    <i className="mdi mdi-format-list-bulleted"></i> Vue
                    Adhérents
                  </Button>
                  <Button
                    color="primary"
                    outline={view != "familles"}
                    onClick={() => changeView("familles")}
                  >
                    <i className="mdi mdi-view-module"></i> Vue Famille
                  </Button>
                </div>
              </Row>
              {view == "adherents" ? (
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField={keyField}
                      columns={listColumns}
                      data={adherents}
                    >
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <ToolkitProvider
                            keyField={keyField}
                            data={adherents}
                            columns={listColumns}
                            bootstrap4
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="8" sm="6" className="mb-2">
                                    <Row>
                                      <Col
                                        xl="4"
                                        lg="6"
                                        md="12"
                                        sm="12"
                                        xs="12"
                                        className="mb-2"
                                      >
                                        <Input
                                          className="form-control"
                                          onChange={event =>
                                            setFilters({
                                              ...filters,
                                              text: event.target.value,
                                            })
                                          }
                                          placeholder={"Nom, prénom, email ..."}
                                          value={filters.text}
                                        />
                                      </Col>
                                      <Col
                                        xl="4"
                                        lg="6"
                                        md="12"
                                        sm="12"
                                        xs="12"
                                        className="mb-2"
                                      >
                                        <Select
                                          value={filters.type}
                                          isMulti={true}
                                          onChange={e => {
                                            setFilters({
                                              ...filters,
                                              type: [...e],
                                            });
                                          }}
                                          options={CATEGORIES_ADHESIONS_OPTIONS}
                                          classNamePrefix="select2-selection"
                                          placeholder="Type adhérent"
                                        />
                                      </Col>
                                      <Col
                                        xl="4"
                                        lg="6"
                                        md="12"
                                        sm="12"
                                        xs="12"
                                        className="mb-2"
                                      >
                                        <Select
                                          value={filters.age}
                                          isMulti={true}
                                          onChange={e => {
                                            setFilters({
                                              ...filters,
                                              age: [...e],
                                            });
                                          }}
                                          options={CATEGORIES_AGE_OPTIONS}
                                          classNamePrefix="select2-selection"
                                          placeholder="Age adhérent"
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="6" sm="6" xs="12">
                                        <Button
                                          color="info"
                                          outline
                                          className=""
                                          onClick={() => searchAdherent()}
                                        >
                                          <i className="bx bx-search-alt search-icon" />{" "}
                                          <span className="hidden-sm">
                                            Rechercher
                                          </span>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="4" sm="6">
                                    <div className="text-sm-end">
                                      <Link to={`${match.url}/new`}>
                                        <Button
                                          color="primary"
                                          className="font-16 btn-block btn btn-primary"
                                        >
                                          <i className="mdi mdi-plus-circle-outline me-1" />
                                          Ajouter un adhérent
                                        </Button>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <Dropdown
                                      isOpen={toggleDropdownActions}
                                      toggle={() =>
                                        setToggleDropdownActions(
                                          !toggleDropdownActions
                                        )
                                      }
                                    >
                                      <DropdownToggle
                                        className="btn btn-secondary"
                                        caret
                                      >
                                        Actions{" "}
                                        <i className="mdi mdi-chevron-down" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          disabled={!selectedRows.length}
                                          onClick={() => setModal(true)}
                                        >
                                          <i className="mdi mdi-clipboard-edit-outline act-icon" />{" "}
                                          Inscrire à une activité
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={keyField}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        selectRow={selectRow}
                                        // defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap table-hover"
                                        }
                                        bordered={false}
                                        striped={false}
                                        responsive
                                        ref={node}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        );
                      }}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              ) : (
                <Row>
                  {map(familles, (famille, key) => (
                    <CardFamille famille={famille} key={"_famille_" + key} />
                  ))}
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AdherentsList);
