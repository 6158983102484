import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from "reactstrap";

import PropTypes from "prop-types";
import { Link, useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Flatepicker
import dayjs from "dayjs";
import "flatpickr/dist/themes/material_blue.css";
import AdherentsSubList from "pages/Adherents/List/AdherentsSubList";
import {
  CATEGORIES_ACTIVITE_OPTIONS,
  CATEGORIES_AGE_OPTIONS,
  LISTE_PRESENCE_STATUS,
} from "utils/constants";
import {
  createListePresence,
  getListePresence,
  getOneActivite,
  updateListePresence,
} from "utils/services";
import PresenceModal from "./PresenceModal";
import { success } from "toastr";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import { DayPicker } from "react-day-picker";

const TextInfo = props => {
  return (
    <div>
      <div>
        <label htmlFor={props.id} className="">
          {props.icon} {props.label}
        </label>
      </div>
      <div>{props.value}</div>
    </div>
  );
};

TextInfo.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
};

const ActivitesView = () => {
  const params = useParams();
  const history = useHistory();

  const [activite, setActivite] = useState({});
  const [creatingList, setCreatingList] = useState(false);
  const [presence, setPresence] = useState({});
  const [modalPresence, setModalPresence] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);

  const [activeTab, setActiveTab] = useState("Info");

  useEffect(() => {
    displayActivite();
  }, []);

  const displayActivite = () => {
    if (params.id) {
      getOneActivite(
        params.id,
        success => {
          setActivite(success);
        },
        error => {
          console.error("Erreur", error);
        }
      );
    }
  };

  const refreshLists = () => {
    getListePresence(
      activite.id,
      success => {
        activite.presences = success.presence;
        setActivite(activite);
      },
      err => {}
    );
  };

  const createNewList = () => {
    setCreatingList(true);
    createListePresence(
      activite.id,
      success => {
        setCreatingList(false);
        displayActivite();
      },
      err => {
        console.error(err);
        setCreatingList(false);
      }
    );
  };

  const updatePresence = presence => {
    updateListePresence(
      {
        id: presence.id,
        presents: presence.presents,
        absents: presence.absents,
        statut: presence.statut,
      },
      success => {
        setModalPresence(false);
        setCreatingList(false);
        displayActivite();
      },
      err => {
        console.error(err);
        setCreatingList(false);
      }
    );
  };

  const displayPresence = nPresence => {
    setPresence(nPresence);
    toggleModalPresence();
  };
  const confirmPresence = nPresence => {
    setPresence(nPresence);
    setConfirmAlert(true);
  };

  const toggleModalPresence = () => {
    setModalPresence(!modalPresence);
  };

  const toggleTab = tab => {
    setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{activite && activite.nomActivite} | Activités | Admin</title>
        </MetaTags>
        <Container fluid={true}>
          <p>
            <Link to="/activites">{"< Retour"}</Link>
          </p>
          <Row>
            <Col sm="6" xs="6">
              <small className="text-muted">ACTIVITÉ</small>
              <Breadcrumbs title={activite && activite.nomActivite} />
              <small className="text-muted">
                {activite
                  ? (activite.createdBy
                      ? "ajouté par " +
                        activite.createdBy.prenom +
                        " - " +
                        dayjs(activite.createdAt).format("DD/MM/YYYY HH:mm") +
                        "\n"
                      : "") +
                    (activite.modifiedBy
                      ? "modifié par " +
                        activite.modifiedBy.prenom +
                        " - " +
                        dayjs(activite.modifiedAt).format("DD/MM/YYYY HH:mm")
                      : "")
                  : null}
              </small>
            </Col>
            <Col sm="6" xs="6" className="text-sm-end ">
              <Link to={`/activites/edit/${activite.id}`}>
                <Button
                  color="primary"
                  className="font-16  btn btn-block btn-primary"
                >
                  <i className="mdi mdi-pencil" id="viewtooltip"></i>
                  Modifier
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Nav tabs className="nav-tabs-custom nav-justified nav-activite">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "Info",
                    })}
                    onClick={() => {
                      toggleTab("Info");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Informations</span>
                  </NavLink>
                </NavItem>
                {activite.inscriptions?.length > 0 && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "Inscrits",
                      })}
                      onClick={() => {
                        toggleTab("Inscrits");
                      }}
                    >
                      <span className="d-none d-sm-block">
                        Inscrits{" "}
                        <Badge
                          className="me-2 bg-info"
                          style={{ fontSize: 14 }}
                        >
                          {activite.inscriptions.length}
                        </Badge>
                      </span>
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "Présences",
                    })}
                    onClick={() => {
                      toggleTab("Présences");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="d-none d-sm-block">Présences</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            {activeTab === "Info" && (
              <Col sm={activite.inscriptions?.length ? 12 : 12} xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Informations</CardTitle>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextInfo
                          label="Dénomination de l'actvité"
                          id="nom-activite"
                          name="nomActivite"
                          value={activite.nomActivite}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInfo
                          label="Catégorie"
                          id="categorie-activite"
                          name="categorieActivite"
                          value={
                            CATEGORIES_ACTIVITE_OPTIONS.find(
                              e => activite.categorieActivite == e.value
                            )?.label
                          }
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextInfo
                          label="Nombre pers. max"
                          id="nb-persons-max"
                          name="nbPersonsMax"
                          value={activite.nbPersonsMax + ""}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInfo
                          label="Tranche d'âge"
                          id="tranche-age-activite"
                          name="trancheAge"
                          value={
                            activite.trancheAge
                              ? CATEGORIES_AGE_OPTIONS.filter(e =>
                                  activite.trancheAge
                                    .split(",")
                                    .find(t => t == e.value)
                                )
                                  .map(({ label }) => label)
                                  .join(",")
                              : ""
                          }
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextInfo
                          label="Date de debut"
                          id="date-debut-activite"
                          name="dateDebut"
                          value={
                            activite.dateDebut &&
                            dayjs(activite.dateDebut).format("DD/MM/YYYY")
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInfo
                          label="Date de fin"
                          id="date-debut-activite"
                          name="dateFin"
                          value={
                            activite.dateFin &&
                            dayjs(activite.dateFin).format("DD/MM/YYYY")
                          }
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextInfo
                          label="Animateur"
                          id="animateur"
                          name="animateur"
                          value={
                            activite.animateur &&
                            activite.animateur.prenom +
                              " " +
                              activite.animateur.nom
                          }
                        />
                      </div>
                    </Row>
                    {!!activite.avecCalendrier &&
                      activite.joursActivite &&
                      activite.joursActivite.length > 0 && (
                        <Row className="mb-3">
                          {" "}
                          <div className="col-md-12">
                            <label>Jours séléctionnés</label>
                          </div>
                          <div className="col-md-12">
                            {activite.joursActivite.map((j, i) => (
                              <span
                                className="m-2 fw-bold bg-info bg-soft p-2 border-radius-pill"
                                key={i}
                              >
                                {dayjs(j.date).format("DD/MM/YYYY")} -{" "}
                                {j.estComplet ? "Complet" : j.nbPersons}
                              </span>
                            ))}

                            {/* <DayPicker
                              fromDate={dayjs(activite.dateDebut).toDate()}
                              toDate={dayjs(activite.dateFin).toDate()}
                              numberOfMonths="2"
                              mode="multiple"
                              min={1}
                              selected={activite.joursActivite.map(j =>
                                dayjs(j.date).toDate()
                              )}
                              disabled={true}
                              onSelect={days => {
                                // formik.setFieldValue("jours", days);
                                // activite.jours = [...days];
                                // setActivite(activite);
                              }}
                              // footer={footer}
                            /> */}
                          </div>
                        </Row>
                      )}
                  </CardBody>
                </Card>
              </Col>
            )}
            {activite.inscriptions?.length > 0 && activeTab === "Inscrits" && (
              <Col sm={12} xs={12}>
                <AdherentsSubList
                  adherents={activite.inscriptions.map(elt => elt.adherent)}
                />
              </Col>
            )}
          </Row>
          {activeTab === "Présences" && (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle>Liste de présence</CardTitle>
                      </Col>
                      <Col className="text-end">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-default"
                          onClick={() => createNewList()}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Nouvelle liste
                        </Button>
                      </Col>
                    </Row>
                    {activite.presences?.length > 0 && (
                      <>
                        <div className="table-responsive">
                          <Table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Statut</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {activite.presences.map((presence, k) => (
                                <tr key={"_prs_" + k}>
                                  <td>
                                    <h5 className="font-size-14 m-0">
                                      <Link to="" className="text-dark">
                                        {dayjs(presence.dateActivite).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Link>
                                    </h5>
                                  </td>
                                  <td>
                                    {LISTE_PRESENCE_STATUS[presence.statut]}
                                  </td>
                                  <td>
                                    <Button
                                      className="btn-sm me-1"
                                      color="info"
                                      outline
                                      onClick={() => displayPresence(presence)}
                                    >
                                      <i
                                        className="mdi mdi-eye font-size-18"
                                        id="edittooltip"
                                      ></i>
                                    </Button>
                                    {presence.statut == "pending" && (
                                      <Button
                                        className="btn-sm"
                                        color="success"
                                        outline
                                        onClick={() =>
                                          confirmPresence(presence)
                                        }
                                        disabled={
                                          !presence.presents ||
                                          !presence.presents.matin ||
                                          !presence.presents.apresmidi
                                        }
                                      >
                                        <i className="mdi mdi-share font-size-18"></i>
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        {!!confirmAlert && (
                          <SweetAlert
                            title="Êtes-vous sûr de valider?"
                            warning
                            showCancel
                            confirmButtonText="Oui"
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            cancelBtnText="Annuler"
                            onConfirm={() => {
                              setConfirmAlert(false);
                              updatePresence({
                                ...presence,
                                statut: "validated",
                              });
                            }}
                            onCancel={() => {
                              setConfirmAlert(false);
                              setPresence(null);
                            }}
                          ></SweetAlert>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Card>
                <CardBody style={{ display: "flex", justifyContent: "center" }}>
                  {/* <Row className="buttons-container">
                  <Col
                    sm={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  > */}
                  <Button
                    color="light"
                    size="lg"
                    style={{ width: "100px" }}
                    onClick={history.goBack}
                  >
                    Retour
                  </Button>
                  {/* </Col>
                </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {modalPresence && (
          <Modal
            size="lg"
            style={{ maxWidth: "600px" }}
            isOpen={modalPresence}
            toggle={toggleModalPresence}
          >
            <ModalHeader>
              <h5 className="modal-title">
                {" "}
                Liste du {dayjs(presence.dateActivite).format("DD/MM/YYYY")}
              </h5>
              <button
                type="button"
                onClick={() => {
                  toggleModalPresence();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody>
              <PresenceModal
                presence={{ ...presence }}
                onCancel={toggleModalPresence}
                onValidate={lPresence => {
                  updatePresence(lPresence);
                }}
              />
            </ModalBody>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default ActivitesView;
