import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";

import PropTypes from "prop-types";
import { Link, useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import { getOneFamille, savePaiements } from "utils/services";

import "toastr/build/toastr.min.css";
import { map, size } from "lodash";
import PaiementModal from "components/PaiementModal";
import dayjs from "dayjs";
import { TYPE_PAIEMENT } from "utils/constants";

const TextInfo = props => {
  return (
    <div>
      <div>
        <label htmlFor={props.id} className="">
          {props.icon} {props.label}
        </label>
      </div>
      <div>{props.value}</div>
    </div>
  );
};

TextInfo.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.object,
};

const FamillesView = () => {
  const params = useParams();
  const history = useHistory();

  const [famille, setFamille] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (params.id) {
      refresh();
    }
  }, []);

  const refresh = () => {
    getOneFamille(
      params.id,
      success => {
        setFamille(success);
      },
      error => {
        console.error("Erreur", error);
      }
    );
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const savePaiement = paiement => {
    savePaiements(
      { ...paiement, familleId: famille.familleId },
      success => {
        setShowModal(false);
        refresh();
      },
      error => {
        console.error(error);
      }
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{(famille && famille.nomFamille) || ""} | Admin</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <p>
              <Link to="/adherents?vue=familles">{"< Retour"}</Link>
            </p>
          </Row>
          <Row>
            <Col>
              <small className="text-muted">FAMILLE</small>
              <Breadcrumbs title={famille && famille.nomFamille} />
            </Col>
            <Col className="text-right">
              <Button
                color="primary"
                className="font-16  btn btn-primary"
                onClick={toggleModal}
              >
                <i className="mdi mdi-pencil" id="viewtooltip"></i>
                Paiement
              </Button>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Row>
                <Col md="6" sm="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Responsable légal 1</CardTitle>
                      <Row className="mb-3">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <TextInfo
                            label="Nom"
                            id="legal-nom"
                            value={famille.nomLegal}
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <TextInfo
                            label="Prénom"
                            id="legal-prenom"
                            value={famille.prenomLegal}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <TextInfo
                            label="Telephone"
                            id="telephone-legal"
                            value={famille.telephoneLegal}
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <TextInfo
                            label="Mobile"
                            id="mobile-legal"
                            value={famille.mobileLegal}
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" sm="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Responsable légal 2</CardTitle>
                      <Row className="mb-3">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <TextInfo
                            label="Nom"
                            id="legal2-nom"
                            value={famille.nomLegal2}
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <TextInfo
                            label="Prénom"
                            id="legal2-prenom"
                            value={famille.prenomLegal2}
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <TextInfo
                            label="Telephone"
                            id="telephone-legal2"
                            value={famille.telephoneLegal2}
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6">
                          <TextInfo
                            label="Mobile"
                            id="mobile-legal2"
                            value={famille.mobileLegal2}
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="12">
              <Card>
                <CardBody>
                  <CardTitle>Membres de la famille</CardTitle>

                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Prénom</th>
                          <th>Activités</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {famille.adherents?.map((adh, k) => (
                          <tr key={"_adh_" + k}>
                            <td>
                              <h5 className="font-size-14 m-0">
                                <Link to="" className="text-dark">
                                  {adh.prenom}
                                </Link>
                              </h5>
                            </td>
                            <td>
                              {size(adh.inscriptions) > 0 &&
                                map(
                                  adh.inscriptions.slice(0, 4),
                                  (inscr, indAct) => (
                                    <span
                                      className="badge bg-primary font-size-11 m-1"
                                      key={"_act_" + indAct}
                                    >
                                      {inscr.activite.nomActivite}
                                    </span>
                                  )
                                )}
                              {size(adh.inscriptions) > 3 && (
                                <span className="badge bg-primary font-size-11 m-1">
                                  ...
                                </span>
                              )}
                            </td>
                            <td>
                              <Link
                                className="text-primary"
                                to={"/adherents/view/" + adh.id}
                              >
                                <Button
                                  className="btn-sm me-1"
                                  color="info"
                                  outline
                                >
                                  <i
                                    className="mdi mdi-eye font-size-18"
                                    id="edittooltip"
                                  ></i>
                                </Button>
                              </Link>
                              <Link
                                className="text-success"
                                to={"/adherents/edit/" + adh.id}
                              >
                                <Button
                                  className="btn-sm me-1"
                                  color="danger"
                                  outline
                                >
                                  <i
                                    className="mdi mdi-pencil font-size-18"
                                    id="viewtooltip"
                                  ></i>
                                </Button>
                                {/* <Link
                                  className="text-success"
                                  to={"/adherents/edit/" + adh.id}
                                >
                                  <Button
                                    className="btn-sm"
                                    color="primary"
                                    outline
                                  >
                                    <i className="mdi mdi-clipboard-edit-outline act-icon font-size-18"></i>
                                  </Button>
                                </Link> */}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" md="6" sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <CardTitle>Paiements</CardTitle>
                    </Col>
                    <Col className="text-right">
                      <Button
                        color="primary"
                        className="font-16  btn btn-primary"
                        onClick={toggleModal}
                      >
                        <i className="mdi mdi-pencil" id="viewtooltip"></i>
                        Paiement
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="6" xs="12">
                      <div className="mt-4 bg-warning bg-soft p-1">
                        <p>Total à régler</p>
                        <h4>{famille.montantTotal} €</h4>
                      </div>
                    </Col>
                    <Col sm="6" xs="12">
                      <div className="mt-4 bg-info bg-soft p-1">
                        <p>Total payé</p>
                        <h4>{famille.montantPayeTotal} €</h4>
                      </div>
                    </Col>
                  </Row>

                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Mode</th>
                          <th>Montant</th>
                          <th>Commentaire</th>
                        </tr>
                      </thead>
                      <tbody>
                        {famille.paiements?.map((pmt, k) => (
                          <tr key={"_adh_" + k}>
                            <td>
                              {dayjs(pmt.datePaiement).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              {
                                TYPE_PAIEMENT.find(e => pmt.mode == e.value)
                                  ?.label
                              }
                            </td>
                            <td>{parseFloat(pmt.montant).toFixed(2)} €</td>
                            <td>{pmt.commentaire}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {showModal && (
          <Modal size="l" isOpen={showModal} toggle={toggleModal}>
            <ModalHeader>
              <h5 className="modal-title">Paiement</h5>
              <button
                type="button"
                onClick={() => {
                  toggleModal();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </ModalHeader>
            <ModalBody>
              <PaiementModal
                onClose={toggleModal}
                onValidate={lPaiement => {
                  savePaiement(lPaiement);
                }}
              />
            </ModalBody>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};

export default FamillesView;
