import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import MetaTags from "react-meta-tags";
import { Link, useRouteMatch, withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb

//redux
import { useDispatch } from "react-redux";

import { ROLE_OPTIONS } from "utils/constants";
import { getUtilisateurs } from "../../../utils/services";

const UtilisateursList = props => {
  let match = useRouteMatch();

  const [utilisateurs, setUtilisateurs] = useState([]);

  const { SearchBar } = Search;
  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: utilisateurs.length, // replace later with size(users),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true,
  };

  const contactListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id}</>,
    },
    {
      text: "Nom",
      dataField: "nom",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.nom} {user.prenom}
            </Link>
          </h5>
          <p className="text-muted mb-0">{user.username}</p>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      formatter: (cellContent, user) =>
        ROLE_OPTIONS.find(e => user.role == e.value)?.label,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-primary" to={"/utilisateurs/view/" + user.id}>
            <Button className="btn-sm" color="info" outline>
              <i className="mdi mdi-eye font-size-18" id="edittooltip"></i>
            </Button>
          </Link>
          <Link className="text-success" to={"/utilisateurs/edit/" + user.id}>
            <Button className="btn-sm" color="danger" outline>
              <i className="mdi mdi-pencil font-size-18" id="viewtooltip"></i>
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getUtilisateurs(
      success => {
        console.log(success);
        setUtilisateurs(success);
      },
      err => {}
    );
  }, []);

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Utilisateurs | Admin</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Adhésion" breadcrumbItem="User List" /> */}
          <h1 className="mb-3">Liste des utilisateurs</h1>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={contactListColumns}
                    data={utilisateurs}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={utilisateurs}
                          columns={contactListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        style={{ borderRadius: "0.25rem" }}
                                        placeholder="Nom, prénom, email"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Link to={`${match.url}/new`}>
                                      <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                      >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Ajouter un utilisateur
                                      </Button>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UtilisateursList);
