import { French } from "flatpickr/dist/l10n/fr";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import { ROLE_OPTIONS } from "utils/constants";
import { getOneUtilisateur, saveUtilisateur } from "utils/services";
import * as Yup from "yup";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const TextField = props => {
  return (
    <>
      <label htmlFor={props.id} className="">
        {props.label}
      </label>
      {props.type == "date" ? (
        <InputGroup>
          <Flatpickr
            className="form-control d-block"
            placeholder="DD/MM/YYYY"
            options={{
              maxDate: new Date(),
              locale: French,
              altInput: true,
              altFormat: "d/m/Y",
              dateFormat: "Y-m-d",
            }}
          />
        </InputGroup>
      ) : (
        <Input
          id={props.id}
          className="form-control"
          name={props.name}
          type={props.type || "text"}
          onChange={props.formik.handleChange}
          onBlur={props.formik.handleBlur}
          placeholder={props.placeholder || ""}
          value={props.formik.values[props.name] || ""}
          invalid={
            props.formik.touched[props.name] && props.formik.errors[props.name]
              ? true
              : false
          }
        />
      )}
      {props.formik.touched[props.name] && props.formik.errors[props.name] ? (
        <FormFeedback type="invalid">
          {props.formik.errors[props.name]}
        </FormFeedback>
      ) : null}
    </>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  formik: PropTypes.object,
};

const UtilisateursForm = () => {
  const params = useParams();
  const history = useHistory();

  const [utilisateur, setUtilisateur] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setAuthUser(obj);
    console.log("authUser", obj);

    if (params.id) {
      getOneUtilisateur(
        params.id,
        {},
        success => {
          setIsEdit(true);
          setUtilisateur(success);
        },
        error => {
          console.error("Erreur", error);
        }
      );
    }
  }, []);

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ...utilisateur,
      password: null,
    },
    validationSchema: Yup.object({
      nom: Yup.string().required("Obligatoire"),
      prenom: Yup.string().required("Obligatoire"),
      username: Yup.string().required("Obligatoire"),
      password: !isEdit && Yup.string().required("Obligatoire"),
      role: Yup.string().required("Obligatoire"),
    }),
    onSubmit: values => {
      console.log("onSubmit");

      saveUtilisateur(
        values,
        success => {
          console.log("save utilisateur", success);
          history.push("/utilisateurs");
        },
        error => {
          console.error(error);
          if (error.message) {
            setErrorMsg(error.message);
          }
        }
      );
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {(isEdit &&
              utilisateur &&
              utilisateur.prenom + " " + utilisateur.nom) ||
              "Nouvel utilisateur"}{" "}
            | Utilisateurs | Admin
          </title>
        </MetaTags>
        <Container fluid={true}>
          <small className="text-muted">UTILISATEUR</small>

          <Breadcrumbs
            title={
              isEdit
                ? utilisateur && utilisateur.prenom + " " + utilisateur.nom
                : formik.values.nom || formik.values.prenom
                ? (formik.values.prenom || "") + " " + (formik.values.nom || "")
                : "Nouvel utilisateur"
            }
            breadcrumbItem={isEdit ? "Modification utilisateur" : ""}
          />

          <Form>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Informations</CardTitle>
                    {errorMsg != "" && (
                      <Row className="mb-3">
                        <Alert color="danger" role="alert">
                          {errorMsg}
                        </Alert>
                      </Row>
                    )}
                    <Row className="mb-3">
                      <div className="col-md-6 col-sm-6">
                        <TextField
                          label="Nom"
                          id="nom-utilisateur"
                          name="nom"
                          formik={formik}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <TextField
                          label="Prénom"
                          id="prenom-utilisateur"
                          name="prenom"
                          formik={formik}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6 col-sm-6">
                        <TextField
                          label="Nom d'utilisateur"
                          id="username-utilisateur"
                          name="username"
                          formik={formik}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <TextField
                          label="Mot de passe"
                          id="password-utilisateur"
                          name="password"
                          formik={formik}
                          type="password"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6 col-sm-6">
                        <TextField
                          label="Email"
                          id="email-utilisateur"
                          name="email"
                          formik={formik}
                          type="email"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <TextField
                          label="Telephone"
                          id="telephone-utilisateur"
                          name="telephone"
                          formik={formik}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6 col-sm-6">
                        <label htmlFor="role-utilisateur" className="">
                          Role
                        </label>
                        <Select
                          isDisabled={
                            !authUser || !authUser.roles.includes("ADMIN")
                          }
                          id="role-utilisateur"
                          value={ROLE_OPTIONS.find(
                            e => e.value == formik.values.role
                          )}
                          onChange={({ label, value }) => {
                            formik.setFieldValue("role", value);
                          }}
                          options={ROLE_OPTIONS}
                          placeholder="Role"
                          classNamePrefix="select2-classe-type"
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Card>
                <CardBody>
                  <Row className="buttons-container">
                    <Col
                      lg={6}
                      sm={6}
                      xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        color="light"
                        size="lg"
                        style={{ width: "100%" }}
                        onClick={history.goBack}
                      >
                        Annuler
                      </Button>
                    </Col>
                    <Col
                      lg={6}
                      sm={6}
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Button
                        color="primary"
                        size="lg"
                        style={{ width: "100%" }}
                        onClick={() => formik.submitForm()}
                      >
                        Valider
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UtilisateursForm;
