import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import images from "assets/images";
import { isEmpty, size, map } from "lodash";

const CardFamille = props => {
  const { famille } = props;

  return (
    <React.Fragment>
      <Col xl="3" sm="6" xs="12">
        <Card className="text-center">
          <CardBody>
            {!famille.img ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-" +
                    famille.color +
                    " text-" +
                    famille.color +
                    " font-size-16"
                  }
                >
                  {famille.nomFamille.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-sm"
                  src={images[famille.img]}
                  alt=""
                />
              </div>
            )}

            <h5 className="font-size-15 mb-1">
              <Link to="#" className="text-dark">
                {famille.nomFamille}
              </Link>
            </h5>
            {/* <p className="text-muted">{famille.designation}</p> */}

            <div>
              {size(famille.adherents) > 0 &&
                map(famille.adherents.slice(0, 4), (adh, ind) => (
                  <Link
                    to={"/adherents/view/" + adh.id}
                    className="badge bg-primary font-size-11 m-1"
                    key={"_adh_" + ind}
                  >
                    {adh.prenom}
                  </Link>
                ))}
              {size(famille.adherents) > 3 && (
                <Link to="#" className="badge bg-primary font-size-11 m-1">
                  +...
                </Link>
              )}
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <Link
                  to={"/familles/view/" + famille.familleId}
                  id={"view-famille-" + famille.familleId}
                >
                  <i className="mdi mdi-eye" />
                </Link>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardFamille.propTypes = {
  famille: PropTypes.object,
};

export default CardFamille;
