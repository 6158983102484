import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { map } from "lodash";
import images from "assets/images";

const AdherentsSubList = ({ adherents }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Inscrits à cette activité</CardTitle>

        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <tbody>
              {adherents.map((adh, k) => (
                <tr key={"_member_" + k}>
                  <td style={{ width: "50px" }}>
                    {adh.img ? (
                      <img
                        src={images[adh.img]}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    ) : null}
                  </td>
                  <td>
                    <h5 className="font-size-14 m-0">
                      <Link to="" className="text-dark">
                        {adh.nom} {adh.prenom}
                      </Link>
                    </h5>
                  </td>
                  {/* <td>{adh.telephone}</td> */}
                  <td>
                    <div></div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

AdherentsSubList.propTypes = {
  adherents: PropTypes.array,
};

export default AdherentsSubList;
