import { del, get, post, put } from "./api";

export const login = (data, successCallback, errorCallback) => {
  return post("/auth/login", data, successCallback, errorCallback);
};

export const getUtilisateurs = (successCallback, errorCallback) => {
  return get("/utilisateurs", {}, successCallback, errorCallback);
};

export const getOneUtilisateur = (id, successCallback, errorCallback) => {
  return get("/utilisateurs/" + id, successCallback, errorCallback);
};

export const saveUtilisateur = (data, successCallback, errorCallback) => {
  if (data.id) {
    return put(
      "/utilisateurs/" + data.id,
      data,
      successCallback,
      errorCallback
    );
  } else {
    return post("/utilisateurs", data, successCallback, errorCallback);
  }
};

export const getFamilles = (data, successCallback, errorCallback) => {
  return get("/familles", data, successCallback, errorCallback);
};

export const getOneFamille = (id, successCallback, errorCallback) => {
  return get("/familles/" + id, {}, successCallback, errorCallback);
};

export const getAdherents = (data, successCallback, errorCallback) => {
  return get("/adherents", data, successCallback, errorCallback);
};

export const getOneAdherent = (id, successCallback, errorCallback) => {
  return get("/adherents/" + id, {}, successCallback, errorCallback);
};

export const saveAdherent = (data, successCallback, errorCallback) => {
  let dataToSend = {};
  // if (data.files) {
  dataToSend = new FormData();
  for (let key in data) {
    let formKey = "";
    if (key == "files") {
      for (let f in data.files) {
        dataToSend.append("files[" + f + "].file", data.files[f].file);
        dataToSend.append(
          "files[" + f + "].documentType",
          data.files[f].documentType
        );
      }
    } else if (Array.isArray(data[key])) {
      for (let it in data[key]) {
        dataToSend.append(formKey + key + "[" + it + "]", data[key][it]);
      }
    } else if (typeof data[key] === "object") {
      for (let subkey in data[key]) {
        dataToSend.append(formKey + key + "." + subkey, data[key][subkey]);
      }
    } else {
      dataToSend.append(formKey + key, data[key]);
    }
  }
  // formData.append('adh', JSON.stringify(sauce));
  // formData.append('image', image);
  // } else {
  //   dataToSend.adherent = data;
  // }

  if (data.id) {
    return put(
      "/adherents/" + data.id,
      dataToSend,
      successCallback,
      errorCallback
    );
  } else {
    return post("/adherents", dataToSend, successCallback, errorCallback);
  }
};

export const getActivites = (data, successCallback, errorCallback) => {
  return get("/activites", data, successCallback, errorCallback);
};

export const getOneActivite = (id, successCallback, errorCallback) => {
  return get("/activites/" + id, {}, successCallback, errorCallback);
};

export const saveActivite = (data, successCallback, errorCallback) => {
  if (data.id) {
    return put("/activites/" + data.id, data, successCallback, errorCallback);
  } else {
    return post("/activites", data, successCallback, errorCallback);
  }

  // return post("/activites", data, successCallback, errorCallback);
};

export const getListePresence = (id, successCallback, errorCallback) => {
  return get("/activites/presence/" + id, {}, successCallback, errorCallback);
};

export const createListePresence = (
  idActivite,
  successCallback,
  errorCallback
) => {
  return get(
    "/activites/presence/create/" + idActivite,
    {},
    successCallback,
    errorCallback
  );
};
export const updateListePresence = (data, successCallback, errorCallback) => {
  return put(
    "/activites/presence/" + data.id,
    data,
    successCallback,
    errorCallback
  );
};

export const saveInscription = (data, successCallback, errorCallback) => {
  return post("/inscrire", data, successCallback, errorCallback);

  // return post("/activites", data, successCallback, errorCallback);
};

export const deleteInscritpion = (id, data, successCallback, errorCallback) => {
  return del("/desinscrire/" + id, data, successCallback, errorCallback);
};

export const getPaiements = (data, successCallback, errorCallback) => {
  return get("/paiements", data, successCallback, errorCallback);
};

export const getOnePaiement = (id, successCallback, errorCallback) => {
  return get("/paiements/" + id, {}, successCallback, errorCallback);
};

export const savePaiements = (data, successCallback, errorCallback) => {
  return post("/paiements", data, successCallback, errorCallback);
};
