import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { MetaTags } from "react-meta-tags";
import { Link, useRouteMatch, withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Input, Row } from "reactstrap";

//redux
import { CATEGORIES_ACTIVITE_OPTIONS } from "utils/constants";
import { getActivites } from "utils/services";

const ActivitesList = props => {
  let match = useRouteMatch();

  const [activiteList, setActviteList] = useState([]);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: activiteList.length, // replace later with size(users),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: true,
  };

  const activiteListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: act => <>{act.id}</>,
    },
    {
      text: "Nom",
      dataField: "nomActivite",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, act) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {act.nomActivite}
            </Link>
          </h5>
          {/* <p className="text-muted mb-0">{act.designation}</p> */}
        </>
      ),
    },
    {
      text: "Categorie",
      dataField: "categorieActivite",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, act) =>
        CATEGORIES_ACTIVITE_OPTIONS.find(e => act.categorieActivite == e.value)
          ?.label,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, act) => (
        <div className="d-flex gap-3">
          <Link className="text-primary" to={"/activites/view/" + act.id}>
            <Button className="btn-sm" color="info" outline>
              <i className="mdi mdi-eye font-size-18" id="edittooltip"></i>
            </Button>
          </Link>
          <Link className="text-success" to={"/activites/edit/" + act.id}>
            <Button className="btn-sm" color="danger" outline>
              <i className="mdi mdi-pencil font-size-18" id="viewtooltip"></i>
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    searchActivites();
  }, []);

  const searchActivites = text => {
    setLoading(true);
    const query = {};

    if (text) {
      query.text = text;
    }
    getActivites(
      query,
      success => {
        console.log(success);
        setActviteList(success);
      },
      err => {}
    );
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Activités | Admin</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Adhésion" breadcrumbItem="User List" /> */}
          <h1 className="mb-3">Liste des activités</h1>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={activiteListColumns}
                    data={activiteList}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={activiteList}
                          columns={activiteListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4" xs="10" className="mb-2">
                                  <Input
                                    className="form-control"
                                    onChange={event =>
                                      setText(event.target.value)
                                    }
                                    placeholder={"Activité ..."}
                                    value={text}
                                  />
                                </Col>
                                <Col sm="2" xs="2">
                                  <Button
                                    color="light"
                                    className=""
                                    outline
                                    onClick={() => searchActivites(text)}
                                  >
                                    <i className="bx bx-search-alt search-icon" />
                                  </Button>
                                  {/* </div>
                                  </div> */}
                                </Col>
                                <Col sm="6">
                                  <div className="text-sm-end">
                                    <Link to={`${match.url}/new`}>
                                      <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                      >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Créer une activité
                                      </Button>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ActivitesList);
