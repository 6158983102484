import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Input, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { getListePresence } from "utils/services";
import { propTypes } from "react-bootstrap-editable";

const PresenceModal = ({ presence, onCancel, onValidate }) => {
  const [presenceData, setPresenceData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getListePresence(
      presence.id,
      success => {
        if (!success.presents) {
          success.presents = { matin: [], apresmidi: [] };
        }
        if (!success.absents) {
          success.absents = { matin: [], apresmidi: [] };
        }
        setPresenceData(success);
        setLoading(false);
      },
      error => {
        console.error(error);
        setLoading(false);
      }
    );
  }, []);

  const applyPresence = (type, time, adh) => {
    const presents = presenceData.presents;
    const absents = presenceData.absents;

    if (type == "presents") {
      if (!presents || !presents[time].includes(adh.id)) {
        presents[time].push(adh.id);
      }

      absents[time] = absents[time].filter(id => id != adh.id);
    } else {
      if (!absents || !absents[time].includes(adh.id)) {
        absents[time].push(adh.id);
      }

      presents[time] = presents[time].filter(id => id != adh.id);
    }

    setPresenceData({
      ...presenceData,
      presents,
      absents,
    });
  };
  console.log("rendering", presenceData.absents, presenceData.presents);
  return (
    <div className="table-responsive">
      {loading ? null : (
        <>
          <Table className="table align-middle table-nowrap">
            <thead>
              <tr>
                <th></th>
                <th>Matin</th>
                <th>Aprés-midi</th>
              </tr>
            </thead>
            <tbody>
              {presenceData.adherents.map((adh, k) => (
                <tr key={"_member_" + k}>
                  <td>
                    <h5 className="font-size-14 m-0">
                      {adh.nom} {adh.prenom}
                    </h5>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                      className=""
                    >
                      {" "}
                      <Button
                        disabled={presenceData.statut != "pending"}
                        color="primary"
                        outline={!presenceData.presents.matin.includes(adh.id)}
                        onClick={() => {
                          applyPresence("presents", "matin", adh);
                        }}
                      >
                        Présent
                      </Button>
                      <Button
                        disabled={presenceData.statut != "pending"}
                        color="danger"
                        outline={!presenceData.absents.matin.includes(adh.id)}
                        onClick={() => {
                          applyPresence("absents", "matin", adh);
                        }}
                      >
                        Absent
                      </Button>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                      className=""
                    >
                      {" "}
                      <Button
                        disabled={presenceData.statut != "pending"}
                        color="primary"
                        outline={
                          !presenceData.presents.apresmidi.includes(adh.id)
                        }
                        onClick={() => {
                          applyPresence("presents", "apresmidi", adh);
                        }}
                      >
                        Présent
                      </Button>
                      <Button
                        disabled={presenceData.statut != "pending"}
                        color="danger"
                        outline={
                          !presenceData.absents.apresmidi.includes(adh.id)
                        }
                        onClick={() => {
                          applyPresence("absents", "apresmidi", adh);
                        }}
                      >
                        Absent
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div
            className="row justify-content-center"
            style={{ display: "flex" }}
          >
            <Col sm={6} xs={12} className="text-center">
              <Button
                className="mb-2"
                style={{ width: "80%" }}
                color="light"
                onClick={() => onCancel()}
              >
                Fermer
              </Button>
            </Col>
            {presenceData.statut == "pending" && (
              <Col sm={6} xs={12} className="text-center">
                <Button
                  className="mb-2"
                  style={{ width: "80%" }}
                  color="success"
                  onClick={() => onValidate(presenceData)}
                >
                  Enregistrer
                </Button>
              </Col>
            )}
          </div>
        </>
      )}
    </div>
  );
};

PresenceModal.propTypes = {
  presence: PropTypes.object,
  onCancel: PropTypes.func,
  onValidate: PropTypes.func,
};

export default PresenceModal;
