import axios from "axios";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import { refreshToken } from "store/actions";
import store from "../store/index";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // "Content-Type": "application/json",
    // Authorization: Store.getState().appStore.restaurant.token,
  },
});

instance.interceptors.request.use(
  config => {
    config.headers["Authorization"] = authHeader().Authorization || "";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

const { dispatch } = store;

instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (authUser && authUser.refreshToken) {
        originalRequest._retry = true;
        try {
          const rs = await instance.post("/auth/refreshToken", {
            refreshToken: authUser.refreshToken,
          });
          const { accessToken } = rs.data;
          dispatch(refreshToken(accessToken));

          authUser.accessToken = accessToken;
          localStorage.setItem("authUser", JSON.stringify(authUser));

          const defaultAuthHeader = authHeader().Authorization || "";
          instance.defaults.headers.common["Authorization"] = defaultAuthHeader;

          // 3) return originalRequest object with Axios.
          originalRequest.headers["Authorization"] = defaultAuthHeader;
          return axios(originalRequest);
        } catch (err) {
          console.log("ERROR IS " + err);
          localStorage.removeItem("authUser");
          window.location = "/login";
          return Promise.reject(error);
        }
      } else {
        localStorage.removeItem("authUser");
        if (
          window.location.pathname != "/" &&
          window.location.pathname != "/login"
        )
          window.location = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export const get = (uri, data, successCallback, failureCallback) => {
  console.log("get", uri);
  return instance
    .get(uri, { params: data })
    .then(response => {
      return successCallback(response.data);
    })
    .catch(err => {
      console.log("ERROR IS " + err);
      if (failureCallback) return failureCallback(err.response);
    });
};

export const post = (uri, data, successCallback, failureCallback) => {
  // console.log('post', uri);
  return instance
    .post(uri, data)
    .then(response => {
      // console.log('response', response.data);
      return successCallback(response.data);
    })
    .catch(err => {
      console.log("dans le post", uri);
      console.log(err.response);
      if (failureCallback) return failureCallback(err.response);
    });
};

export const put = (uri, data, successCallback, failureCallback) => {
  console.log("put", uri);

  return instance
    .put(uri, data)
    .then(response => {
      return successCallback(response.data);
    })
    .catch(err => {
      if (failureCallback) return failureCallback(err.response);
    });
};

export const del = (uri, data, successCallback, failureCallback) => {
  console.log("del", uri);

  return instance
    .delete(uri, data)
    .then(response => {
      return successCallback(response.data);
    })
    .catch(err => {
      if (failureCallback) return failureCallback(err.response);
    });
};
