import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Input, Row } from "reactstrap";
import { TYPE_PAIEMENT } from "utils/constants";

const PaiementModal = ({ onClose, onValidate }) => {
  const [paiementData, setPaiementData] = useState({});
  useEffect(() => {}, []);

  return (
    <>
      <Row className="m-2">
        <Col>
          <label htmlFor="montant-paiement" className="">
            Montant
          </label>
          <Input
            className="form-control"
            name="montantPaiement"
            type="text"
            onChange={({ target }) => {
              paiementData.montant = target.value;
              setPaiementData({ ...paiementData });
            }}
            placeholder={0.0}
            value={paiementData.montant || ""}
            required
          />
        </Col>
      </Row>
      <Row className="m-2">
        {" "}
        <Col>
          <label htmlFor="mode-paiement" className="">
            Mode de paiement
          </label>
          <Select
            value={TYPE_PAIEMENT.find(e => paiementData.mode == e.value)}
            onChange={({ label, value }) => {
              paiementData.mode = value;
              setPaiementData({ ...paiementData });
            }}
            name="modePaiement"
            options={TYPE_PAIEMENT}
            placeholder="Choisir un mode de paiement"
            classNamePrefix="select2-mdoe-paiement"
          />
        </Col>
      </Row>
      <Row className="m-2">
        {" "}
        <Col>
          <label htmlFor="info-paiement" className="">
            Commentaire :
          </label>
          <Input
            className="form-control"
            name="commentaire"
            type="textarea"
            onChange={({ target }) => {
              paiementData.commentaire = target.value;
              setPaiementData({ ...paiementData });
            }}
            value={paiementData.commentaire || ""}
          />
        </Col>
      </Row>
      <div
        className="row justify-content-center m-2"
        style={{ display: "flex" }}
      >
        <Col sm={6} xs={12} className="text-center">
          <Button
            className="mb-2"
            style={{ width: "100%" }}
            color="light"
            onClick={() => onClose()}
          >
            Annuler
          </Button>
        </Col>
        <Col sm={6} xs={12} className="text-center">
          <Button
            className="mb-2"
            style={{ width: "100%" }}
            color="success"
            onClick={() => onValidate(paiementData)}
          >
            Enregistrer
          </Button>
        </Col>
      </div>
    </>
  );
};

PaiementModal.propTypes = {
  onClose: PropTypes.func,
  onValidate: PropTypes.func,
};

export default PaiementModal;
