import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
  Form,
  Button,
  InputGroup,
  Dropdown,
  Modal,
  ModalBody,
  Alert,
} from "reactstrap";
import Select from "react-select";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PropTypes, { string } from "prop-types";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { French } from "flatpickr/dist/l10n/fr";
import { DOCUMENT_TYPE_OPTIONS, CLASSE_OPTIONS } from "utils/constants";
import PDFIcon from "../../../assets/images/pdf-icon.png";

import { saveAdherent, getOneAdherent, getFamilles } from "utils/services";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";

import { get } from "utils/api";

dayjs.extend(customParseFormat);
dayjs.extend(timezone);

const TextField = props => {
  return (
    <>
      <label htmlFor={props.id} className="">
        {props.label}
      </label>
      {props.type == "date" ? (
        <InputGroup>
          <Flatpickr
            className="form-control d-block"
            placeholder="DD/MM/YYYY"
            name={props.name}
            onChange={([date]) => {
              props.formik.setFieldValue(
                props.name,
                dayjs(date).format("DD/MM/YYYY")
              );
            }}
            value={props.formik.values[props.name] || ""}
            options={{
              locale: "French",
              altFormat: "d/m/Y",
              dateFormat: "d/m/Y",
            }}
            invalid={
              props.invalid ||
              (props.formik.touched[props.name] &&
                props.formik.errors[props.name])
            }
          />
        </InputGroup>
      ) : (
        <Input
          id={props.id}
          className="form-control"
          name={props.name}
          type={props.type || "text"}
          onChange={props.onChange || props.formik.handleChange}
          onBlur={props.formik.handleBlur}
          placeholder={props.placeholder || ""}
          value={props.value || props.formik.values[props.name] || ""}
          defaultValue={""}
          invalid={
            props.invalid ||
            (props.formik.touched[props.name] &&
              props.formik.errors[props.name])
              ? true
              : false
          }
        />
      )}
      {props.invalid ||
      (props.formik.touched[props.name] && props.formik.errors[props.name]) ? (
        <FormFeedback type="invalid">
          {props.invalid ? props.invalid : props.formik.errors[props.name]}
        </FormFeedback>
      ) : null}
    </>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  formik: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  invalid: PropTypes.string,
};

const ListFamille = ({ list, onItemClick }) => {
  return (
    <ul className="results">
      {list.map(item => (
        <li key={item.id} onClick={() => onItemClick(item)}>
          {item.nomFamille}
          <span className="font-size-13 text-muted">{item.prenomLegal}</span>
        </li>
      ))}
    </ul>
  );
};
ListFamille.propTypes = {
  list: PropTypes.array,
  onItemClick: PropTypes.func,
};

const AdherentsForm = () => {
  const params = useParams();
  const history = useHistory();

  const [adherent, setAdherent] = useState({});
  const [files, setFiles] = useState([]);
  const [fileErrors, setFileErrors] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [displayedFile, setDisplayedFile] = useState(null);
  const [resultList, setResultList] = useState([]);

  const onFileChange = async event => {
    if (event.target.files.length) {
      files.push({ file: event.target.files[0] });
      setFiles([...files]);
    }
  };

  const validationSchema = Yup.object({
    nom: Yup.string().required("Obligatoire"),
    prenom: Yup.string().required("Obligatoire"),
    dateNaissance: Yup.string().required("Obligatoire"),
    adresse: Yup.string().required("Obligatoire"),
    ville: Yup.string().required("Obligatoire"),
    codePostal: Yup.number()
      .typeError("Code postal invalide")
      .required("Obligatoire"),
    etablissementScolaire: Yup.string().required("Obligatoire"),
    classe: Yup.string().required("Obligatoire"),
    numCAF: Yup.string().required("Obligatoire"),
    profession: Yup.string()
      .nullable()
      .when("type", {
        is: type => type == "adulte",
        then: Yup.string().required("Obligatoire"),
      }),

    email: Yup.string().email("Email invalide").required("Obligatoire"),
    telephone: Yup.number()
      .nullable()
      .typeError("Téléphone invalide")
      .when("mobile", {
        is: mobile => !mobile,
        then: Yup.number()
          .nullable()
          .required("Veuillez saisir un numéro de mobile ou fixe"),
      }),
    mobile: Yup.number().nullable().typeError("Format invalide"),
    contactUrgence: Yup.string().required("Obligatoire"),
    telephoneUrgence: Yup.number()
      .typeError("Téléphone invalide")
      .when("mobileUrgence", {
        is: mobileUrgence => !mobileUrgence,
        then: Yup.number()
          .nullable()
          .required("Veuillez saisir un numéro de mobile ou fixe"),
      }),
    mobileUrgence: Yup.number().nullable().typeError("Format invalide"),

    assurance: Yup.object({
      nom: Yup.string().nullable().required("Obligatoire"),
      numContrat: Yup.string().nullable().required("Obligatoire"),
      adresse: Yup.string().nullable().required("Obligatoire"),
      ville: Yup.string().nullable().required("Obligatoire"),
      codePostal: Yup.number()
        .nullable()
        .typeError("Code postal invalide")
        .required("Obligatoire"),
      telephone: Yup.number()
        .nullable()
        .typeError("Format invalide")
        .required("Obligatoire"),
    }),
    famille: Yup.object().when("type", {
      is: type => type != "adulte",
      then: Yup.object().shape({
        nomFamille: Yup.string().nullable().required("Obligatoire"),
        nomLegal: Yup.string().nullable().required("Obligatoire"),
        prenomLegal: Yup.string().nullable().required("Obligatoire"),
        telephoneLegal: Yup.number()
          .nullable()
          .typeError("Format invalide")
          .when("mobileLegal", {
            is: mobileLegal => !mobileLegal,
            then: Yup.number()
              .nullable()
              .typeError("Format invalide")
              .required("Veuillez saisir un numéro de mobile ou fixe"),
          }),
        mobileLegal: Yup.number().nullable().typeError("Format invalide"),
        nomLegal2: Yup.string().nullable().required("Obligatoire"),
        prenomLegal2: Yup.string().nullable().required("Obligatoire"),
        telephoneLegal2: Yup.number()
          .nullable()
          .typeError("Format invalide")
          .when("mobileLegal2", {
            is: mobileLegal2 => !mobileLegal2,
            then: Yup.number()
              .nullable()
              .typeError("Format invalide")
              .required("Veuillez saisir un numéro de mobile ou fixe"),
          }),
        mobileLegal2: Yup.number().nullable().typeError("Format invalide"),
      }),
    }),
  });

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nom: "",
      type: "jeunesse",
      assurance: {},
      famille: {},
    },
    // validationSchema: Yup.object({
    //   nom: Yup.string().required("Obligatoire"),
    //   prenom: Yup.string().required("Obligatoire"),
    //   dateNaissance: Yup.string().required("Obligatoire"),
    //   adresse: Yup.string().required("Obligatoire"),
    //   ville: Yup.string().required("Obligatoire"),
    //   codePostal: Yup.number()
    //     .typeError("Code postal invalide")
    //     .required("Obligatoire"),
    //   etablissementScolaire: Yup.string().required("Obligatoire"),
    //   classe: Yup.string().required("Obligatoire"),
    //   numCAF: Yup.string().required("Obligatoire"),
    //   profession: Yup.string()
    //     .nullable()
    //     .when("type", {
    //       is: type => type == "adulte",
    //       then: Yup.string().required("Obligatoire"),
    //     }),

    //   email: Yup.string().email("Email invalide").required("Obligatoire"),
    //   telephone: Yup.number()
    //     .nullable()
    //     .typeError("Téléphone invalide")
    //     .when("mobile", {
    //       is: mobile => !mobile,
    //       then: Yup.number()
    //         .nullable()
    //         .required("Veuillez saisir un numéro de mobile ou fixe"),
    //     }),
    //   mobile: Yup.number().nullable().typeError("Format invalide"),
    //   contactUrgence: Yup.string().required("Obligatoire"),
    //   telephoneUrgence: Yup.number()
    //     .typeError("Téléphone invalide")
    //     .when("mobileUrgence", {
    //       is: mobileUrgence => !mobileUrgence,
    //       then: Yup.number()
    //         .nullable()
    //         .required("Veuillez saisir un numéro de mobile ou fixe"),
    //     }),
    //   mobileUrgence: Yup.number().nullable().typeError("Format invalide"),

    //   assurance: Yup.object({
    //     nom: Yup.string().nullable().required("Obligatoire"),
    //     numContrat: Yup.string().nullable().required("Obligatoire"),
    //     adresse: Yup.string().nullable().required("Obligatoire"),
    //     ville: Yup.string().nullable().required("Obligatoire"),
    //     codePostal: Yup.number()
    //       .nullable()
    //       .typeError("Code postal invalide")
    //       .required("Obligatoire"),
    //     telephone: Yup.number()
    //       .nullable()
    //       .typeError("Format invalide")
    //       .required("Obligatoire"),
    //   }),
    //   famille: Yup.object().when("type", {
    //     is: type => type != "adulte",
    //     then: Yup.object().shape({
    //       nomFamille: Yup.string().nullable().required("Obligatoire"),
    //       nomLegal: Yup.string().nullable().required("Obligatoire"),
    //       prenomLegal: Yup.string().nullable().required("Obligatoire"),
    //       telephoneLegal: Yup.number()
    //         .nullable()
    //         .typeError("Format invalide")
    //         .when("mobileLegal", {
    //           is: mobileLegal => !mobileLegal,
    //           then: Yup.number()
    //             .nullable()
    //             .typeError("Format invalide")
    //             .required("Veuillez saisir un numéro de mobile ou fixe"),
    //         }),
    //       mobileLegal: Yup.number().nullable().typeError("Format invalide"),
    //       nomLegal2: Yup.string().nullable().required("Obligatoire"),
    //       prenomLegal2: Yup.string().nullable().required("Obligatoire"),
    //       telephoneLegal2: Yup.number()
    //         .nullable()
    //         .typeError("Format invalide")
    //         .when("mobileLegal2", {
    //           is: mobileLegal2 => !mobileLegal2,
    //           then: Yup.number()
    //             .nullable()
    //             .typeError("Format invalide")
    //             .required("Veuillez saisir un numéro de mobile ou fixe"),
    //         }),
    //       mobileLegal2: Yup.number().nullable().typeError("Format invalide"),
    //     }),
    //   }),
    // }),

    onSubmit: async values => {
      console.log("onSubmit");
      try {
        await validationSchema.validate(values, {
          abortEarly: false,
        });
      } catch (err) {
        console.error(err);
      }
      // const fichiers = adherent.fichiers?.filter(
      //   f => !deletedFiles.find(df => df == f.fileName)
      // );
      // const errors = [];
      // DOCUMENT_TYPE_OPTIONS.filter(o => !o.optional).forEach(option => {
      //   if (
      //     !files.find(f => f.documentType == option.value) &&
      //     (!fichiers || !fichiers.find(f => f.fileType == option.value))
      //   ) {
      //     errors.push(`Document "${option.label}" manquant`);
      //   }
      // });

      // setFileErrors(errors);
      // if (errors.length) {
      //   return;
      // }

      onSave("valide", values);
    },
  });

  const onSave = (statut = "valide", values) => {
    delete values.fichiers;
    values.deletedFiles = deletedFiles;
    if (files.length) {
      values.files = files;
    }
    values.statut = statut;
    saveAdherent(
      values,
      success => {
        console.log("save adhérent", success);
        history.push("/adherents");
      },
      error => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    if (params.id) {
      getOneAdherent(
        params.id,
        success => {
          console.log("useEffect", success);
          setIsEdit(true);
          setAdherent(success);

          setIsDraft(
            !success.id ||
              !success.adhesion ||
              success.adhesion.statut != "valide"
          );

          formik.resetForm();
          // const d1 = dayjs.tz("2013-11-18 11:55", "Asia/Taipei");
          // console.log(
          //   success.dateNaissance,
          //   dayjs(success.dateNaissance).tz("Europe/Paris")
          // );

          const editValues = {
            type: "jeunesse",
            ...success,
            assurance: success.assurance || {},
            famille: success.famille || {},
            dateNaissance: success.dateNaissance
              ? dayjs(success.dateNaissance).format("DD/MM/YYYY")
              : "",
          };

          delete editValues.adhesion;

          editValues.assurance.responsabiliteIndividuelle =
            (success.assurance &&
              success.assurance.responsabiliteIndividuelle) ||
            0;

          editValues.assurance.responsabiliteCivile =
            (success.assurance && success.assurance.responsabiliteCivile) || 0;

          formik.setValues(editValues);
        },
        error => {
          console.error("Erreur", error);
        }
      );
    }
  }, []);

  const onViewFile = file => {
    setDisplayedFile(file);
    setViewModal(true);
  };

  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  const searchFamilles = value => {
    getFamilles(
      { search: value, inCreation: 1 },
      success => {
        setResultList(success);
      },
      error => {}
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {(isEdit && adherent && adherent.prenom + " " + adherent.nom) ||
            formik.values.nom ||
            formik.values.prenom
              ? (formik.values.prenom || "") + " " + (formik.values.nom || "")
              : "Nouvel adhérent"}{" "}
            | Adhérents | Admin
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs
            title={
              isEdit
                ? adherent && adherent.prenom + " " + adherent.nom
                : formik.values.nom || formik.values.prenom
                ? (formik.values.prenom || "") + " " + (formik.values.nom || "")
                : "Nouvel adhérent"
            }
            breadcrumbItem={
              isEdit ? "Modification adhérent" : "Nouvel adhérent"
            }
          />

          <Form>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Informations</CardTitle>
                    <Row className="mb-3">
                      <div className="col-md-6 search">
                        <TextField
                          label="Famille"
                          id="nom-famille"
                          name="famille[nomFamille]"
                          onChange={event => {
                            formik.handleChange(event);
                            if (event.target.value.length > 2) {
                              searchFamilles(event.target.value);
                            }
                          }}
                          formik={formik}
                          value={formik.values.famille.nomFamille}
                          invalid={
                            formik.touched.famille &&
                            formik.touched.famille.nomFamille &&
                            formik.errors.famille &&
                            formik.errors.famille.nomFamille
                              ? formik.errors.famille.nomFamille
                              : ""
                          }
                        />
                        {resultList.length > 0 && (
                          <ListFamille
                            list={resultList}
                            onItemClick={item => {
                              formik.setFieldValue("famille", item);
                              setResultList([]);
                            }}
                          />
                        )}
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="btn-group col-md-6" role="group">
                        <Button
                          color="primary"
                          outline={formik.values.type != "enfance"}
                          onClick={() =>
                            formik.setFieldValue("type", "enfance")
                          }
                        >
                          <i className="fas fa-child"></i> Action Enfance
                        </Button>
                        <Button
                          color="primary"
                          outline={formik.values.type != "jeunesse"}
                          onClick={() =>
                            formik.setFieldValue("type", "jeunesse")
                          }
                        >
                          <i className="fas fa-child"></i> Action Jeunesse
                        </Button>
                        <Button
                          color="primary"
                          outline={formik.values.type != "adulte"}
                          onClick={() => formik.setFieldValue("type", "adulte")}
                        >
                          <i className="fas fa-male"></i> Adulte
                        </Button>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Nom"
                          id="nom-adherent"
                          name="nom"
                          formik={formik}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Prénom"
                          id="prenom-adherent"
                          name="prenom"
                          formik={formik}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Date de naissance"
                          id="date-naissance-adherent"
                          name="dateNaissance"
                          formik={formik}
                          type="date"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-12">
                        <TextField
                          label="Adresse"
                          id="adresse-adherent"
                          name="adresse"
                          formik={formik}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Ville"
                          id="ville-adherent"
                          name="ville"
                          formik={formik}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Code postal"
                          id="code-postal-adherent"
                          name="codePostal"
                          formik={formik}
                        />
                      </div>
                    </Row>
                    {formik.values.type != "adulte" && (
                      <Row className="mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Etablissement scolaire"
                            id="etablissement-adherent"
                            name="etablissementScolaire"
                            formik={formik}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="classe-adherent" className="">
                            Classe
                          </label>
                          <Select
                            id="classe-adherent"
                            value={CLASSE_OPTIONS.find(
                              c => c.value == formik.values.classe
                            )}
                            onChange={({ label, value }) => {
                              formik.setFieldValue("classe", value);
                            }}
                            options={CLASSE_OPTIONS}
                            placeholder="Classe"
                            classNamePrefix="select2-classe-type"
                          />
                        </div>
                      </Row>
                    )}
                    <Row className="mb-3">
                      {formik.values.type == "adulte" && (
                        <div className="col-md-6">
                          <TextField
                            label="Profession"
                            id="profession-adherent"
                            name="profession"
                            formik={formik}
                          />
                        </div>
                      )}
                      <div className="col-md-6">
                        <TextField
                          label="Numéro Allocataire CAF"
                          id="numcaf-adherent"
                          name="numCAF"
                          formik={formik}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Email"
                          id="email-adherent"
                          name="email"
                          formik={formik}
                          type="email"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Telephone"
                          id="telephone-adherent"
                          name="telephone"
                          formik={formik}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Mobile"
                          id="mobile-adherent"
                          name="mobile"
                          formik={formik}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-12">
                        <TextField
                          label="Personne à contacter en cas d'urgence"
                          id="urgence-adherent"
                          name="contactUrgence"
                          formik={formik}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <div className="col-md-6">
                        <TextField
                          label="Telephone Urgence"
                          id="urgence-telephone-adherent"
                          name="telephoneUrgence"
                          formik={formik}
                          placeholder="02 12 34 56 78"
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          label="Mobile Urgence"
                          id="urgence-mobile-adherent"
                          name="mobileUrgence"
                          formik={formik}
                          placeholder="02 12 34 56 78"
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {formik.values.type != "adulte" && (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Responsable légal 1</CardTitle>
                      <Row className="mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Nom"
                            id="legal-nom"
                            name="famille[nomLegal]"
                            formik={formik}
                            value={formik.values.famille.nomLegal}
                            invalid={
                              formik.touched.famille &&
                              formik.touched.famille.nomLegal &&
                              formik.errors.famille &&
                              formik.errors.famille.nomLegal
                                ? formik.errors.famille.nomLegal
                                : ""
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Prénom"
                            id="legal-prenom"
                            name="famille[prenomLegal]"
                            formik={formik}
                            value={formik.values.famille.prenomLegal}
                            invalid={
                              formik.touched.famille &&
                              formik.touched.famille.prenomLegal &&
                              formik.errors.famille &&
                              formik.errors.famille.prenomLegal
                                ? formik.errors.famille.prenomLegal
                                : ""
                            }
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Telephone"
                            id="telephone-legal"
                            name="famille[telephoneLegal]"
                            formik={formik}
                            value={formik.values.famille.telephoneLegal}
                            invalid={
                              formik.touched.famille &&
                              formik.touched.famille.telephoneLegal &&
                              formik.errors.famille &&
                              formik.errors.famille.telephoneLegal
                                ? formik.errors.famille.telephoneLegal
                                : ""
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Mobile"
                            id="mobile-legal"
                            name="famille[mobileLegal]"
                            formik={formik}
                            value={formik.values.famille.mobileLegal}
                            invalid={
                              (formik.touched.famille &&
                                formik.touched.famille.mobileLegal &&
                                formik.errors.famille &&
                                formik.errors.famille.mobileLegal) ||
                              (formik.touched.famille &&
                                formik.touched.famille.telephoneLegal &&
                                formik.errors.famille &&
                                formik.errors.famille.telephoneLegal)
                                ? formik.errors.famille.mobileLegal ||
                                  formik.touched.famille.telephoneLegal
                                : ""
                            }
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Responsable légal 2</CardTitle>
                      <Row className="mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Nom"
                            id="legal2-nom"
                            name="famille[nomLegal2]"
                            formik={formik}
                            value={formik.values.famille.nomLegal2}
                            invalid={
                              formik.touched.famille &&
                              formik.touched.famille.nomLegal2 &&
                              formik.errors.famille &&
                              formik.errors.famille.nomLegal2
                                ? formik.errors.famille.nomLegal2
                                : ""
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Prénom"
                            id="legal2-prenom"
                            name="famille[prenomLegal2]"
                            formik={formik}
                            value={formik.values.famille.prenomLegal2}
                            invalid={
                              formik.touched.famille &&
                              formik.touched.famille.prenomLegal2 &&
                              formik.errors.famille &&
                              formik.errors.famille.prenomLegal2
                                ? formik.errors.famille.prenomLegal2
                                : ""
                            }
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <div className="col-md-6">
                          <TextField
                            label="Telephone"
                            id="telephone-legal2"
                            name="famille[telephoneLegal2]"
                            formik={formik}
                            value={formik.values.famille.telephoneLegal2}
                            invalid={
                              formik.touched.famille &&
                              formik.touched.famille.telephoneLegal2 &&
                              formik.errors.famille &&
                              formik.errors.famille.telephoneLegal2
                                ? formik.errors.famille.telephoneLegal2
                                : ""
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Mobile"
                            id="mobile-legal2"
                            name="famille[mobileLegal2]"
                            formik={formik}
                            value={formik.values.famille.mobileLegal2}
                            invalid={
                              (formik.touched.famille &&
                                formik.touched.famille.mobileLegal2 &&
                                formik.errors.famille &&
                                formik.errors.famille.mobileLegal2) ||
                              (formik.touched.famille &&
                                formik.touched.famille.telephoneLegal2 &&
                                formik.errors.famille &&
                                formik.errors.famille.telephoneLegal2)
                                ? formik.errors.famille.mobileLegal2 ||
                                  formik.touched.famille.telephoneLegal2
                                : ""
                            }
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Assurances</CardTitle>
                    <div>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <TextField
                            label="Assureur"
                            id="nom-assurance"
                            name="assurance[nom]"
                            formik={formik}
                            value={formik.values.assurance.nom || ""}
                            invalid={
                              formik.touched.assurance &&
                              formik.touched.assurance.nom &&
                              formik.errors.assurance &&
                              formik.errors.assurance.nom
                                ? formik.errors.assurance.nom
                                : ""
                            }
                          />
                        </Col>
                        <Col lg={6}>
                          <TextField
                            label="Numéro de Contrat"
                            id="contrat-assurance"
                            name="assurance[numContrat]"
                            formik={formik}
                            value={formik.values.assurance.numContrat || ""}
                            invalid={
                              formik.touched.assurance &&
                              formik.touched.assurance.numContrat &&
                              formik.errors.assurance &&
                              formik.errors.assurance.numContrat
                                ? formik.errors.assurance.numContrat
                                : ""
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col lg={12}>
                          <TextField
                            label="Adresse"
                            id="adresse-assurance"
                            name="assurance[adresse]"
                            formik={formik}
                            value={formik.values.assurance.adresse || ""}
                            invalid={
                              formik.touched.assurance &&
                              formik.touched.assurance.adresse &&
                              formik.errors.assurance &&
                              formik.errors.assurance.adresse
                                ? formik.errors.assurance.adresse
                                : ""
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col lg={6}>
                          <TextField
                            label="Ville"
                            id="ville-assurance"
                            name="assurance[ville]"
                            formik={formik}
                            value={formik.values.assurance.ville || ""}
                            invalid={
                              formik.touched.assurance &&
                              formik.touched.assurance.ville &&
                              formik.errors.assurance &&
                              formik.errors.assurance.ville
                                ? formik.errors.assurance.ville
                                : ""
                            }
                          />
                        </Col>
                        <Col lg={6}>
                          <TextField
                            label="Code postal"
                            id="code-postal-assurance"
                            name="assurance[codePostal]"
                            formik={formik}
                            value={formik.values.assurance.codePostal || ""}
                            invalid={
                              formik.touched.assurance &&
                              formik.touched.assurance.codePostal &&
                              formik.errors.assurance &&
                              formik.errors.assurance.codePostal
                                ? formik.errors.assurance.codePostal
                                : ""
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col lg={6}>
                          <TextField
                            label="Téléphone"
                            id="telephone-assurance"
                            name="assurance[telephone]"
                            formik={formik}
                            value={formik.values.assurance.telephone || ""}
                            invalid={
                              formik.touched.assurance &&
                              formik.touched.assurance.telephone &&
                              formik.errors.assurance &&
                              formik.errors.assurance.telephone
                                ? formik.errors.assurance.telephone
                                : ""
                            }
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col lg={12}>
                          <div className="form-check mb-3">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              value="1"
                              id="assurance-checkbox-1"
                              name="assurance[responsabiliteCivile]"
                              onClick={event => {
                                formik.setFieldValue(
                                  "assurance[responsabiliteCivile]",
                                  !formik.values.assurance.responsabiliteCivile
                                );
                              }}
                              checked={
                                formik.values.assurance.responsabiliteCivile
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="assurance-checkbox-1"
                            >
                              Responsabilité civile
                            </label>
                          </div>
                          <div className="form-check mb-3">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              value="1"
                              id="assurance-checkbox-2"
                              name="assurance[responsabiliteIndividuelle]"
                              onClick={event => {
                                formik.setFieldValue(
                                  "assurance[responsabiliteIndividuelle]",
                                  !formik.values.assurance
                                    .responsabiliteIndividuelle
                                );
                              }}
                              checked={
                                formik.values.assurance
                                  .responsabiliteIndividuelle
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="assurance-checkbox-2"
                            >
                              Responsabilité individuelle
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Autorisations</CardTitle>

                    <Row className="mb-3">
                      <Col lg={12}>
                        <div className="form-check mb-3">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="autorisations-checkbox-1"
                            name="autorisationMedicale"
                            onClick={event => {
                              formik.setFieldValue(
                                "autorisationMedicale",
                                !formik.values.autorisationMedicale
                              );
                            }}
                            checked={formik.values.autorisationMedicale}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="autorisations-checkbox-1"
                          >
                            {`Autorise l'équipe à prendre, le cas échéant,
                          toutes mesures (traitement médical, hostpitalisation,
                          intervention chirugicale ...) rendues nécessaires ${
                            formik.values.type == "jeunesse"
                              ? "par l'état des santé de mes enfants"
                              : ""
                          }`}
                          </label>
                        </div>
                        {formik.values.type == "jeunesse" && (
                          <>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="autorisations-checkbox-3"
                                name="autorisationTransport"
                                onClick={event => {
                                  formik.setFieldValue(
                                    "autorisationTransport",
                                    !formik.values.autorisationTransport
                                  );
                                }}
                                checked={formik.values.autorisationTransport}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="autorisations-checkbox-3"
                              >
                                {`Autorise l'équipe à transporter ou faire
                                transporter mon enfant, puor d'éventuelles
                                sorties, par d'autres parents, les animateurs,
                                en transport en commun ou en car`}
                              </label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="autorisations-checkbox-4"
                                name="autorisationSortie"
                                onClick={event => {
                                  formik.setFieldValue(
                                    "autorisationSortie",
                                    !formik.values.autorisationSortie
                                  );
                                }}
                                checked={formik.values.autorisationSortie}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="autorisations-checkbox-4"
                              >
                                {`Autorise mon enfant à rentrer seul(e) le soir
                                après l'activité`}
                              </label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="autorisations-checkbox-5"
                                name="autorisationBaignade"
                                onClick={event => {
                                  formik.setFieldValue(
                                    "autorisationBaignade",
                                    !formik.values.autorisationBaignade
                                  );
                                }}
                                checked={formik.values.autorisationBaignade}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="autorisations-checkbox-5"
                              >
                                Autorise mon enfant à se baigner
                              </label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="autorisations-checkbox-6"
                                name="autorisationPhoto"
                                onClick={event => {
                                  formik.setFieldValue(
                                    "autorisationPhoto",
                                    !formik.values.autorisationPhoto
                                  );
                                }}
                                checked={formik.values.autorisationPhoto}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="autorisations-checkbox-6"
                              >
                                Autorise mon enfant à être photographié
                              </label>
                            </div>
                          </>
                        )}

                        <div className="form-check mb-3">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="autorisations-checkbox-2"
                            name="autorisationCommunication"
                            onClick={event => {
                              formik.setFieldValue(
                                "autorisationCommunication",
                                !formik.values.autorisationCommunication
                              );
                            }}
                            checked={formik.values.autorisationCommunication}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="autorisations-checkbox-2"
                          >
                            Autorise le centre à utiliser les photos pour les
                            supports de communication
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Documents</CardTitle>

                    {fileErrors.length > 0 && (
                      <Alert color="danger" role="alert">
                        {fileErrors.map((err, index) => (
                          <p
                            className="font-weight-semibold font-size-15"
                            key={index}
                          >
                            {err}
                          </p>
                        ))}
                      </Alert>
                    )}
                    <Row>
                      {adherent.fichiers
                        ?.filter(
                          f => !deletedFiles.find(df => df == f.fileName)
                        )
                        .map((fichier, index) => (
                          <Col lg={4} md={4} sm={6} key={index}>
                            <div className="mt-4 document-item">
                              {/* <div style={{ flex: 2 }}> */}
                              {/* <div className="document-preview">
                                {fichier.contentType == "application/pdf" ? (
                                  // <img src={PDFIcon} />
                                  <div className="avatar-sm">
                                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                      <i className="bx bxs-file-pdf" />
                                    </span>
                                  </div>
                                ) : (
                                  // <img
                                  //   src={
                                  //     "data:image/png;base64," +
                                  //     fichier.file.data
                                  //   }
                                  // />
                                  <div className="avatar-sm">
                                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                      <i className="bx bxs-file-image" />
                                    </span>
                                  </div>
                                )}
                              </div> */}
                              <div className="document-name">
                                {fichier.fileType &&
                                fichier.fileType != "undefined"
                                  ? DOCUMENT_TYPE_OPTIONS.find(
                                      dt => dt.value == fichier.fileType
                                    )?.label
                                  : "Type non défini"}
                              </div>
                              <div className="document-delete-button">
                                <Button
                                  color="info"
                                  outline
                                  className="me-2"
                                  onClick={() => onViewFile(fichier)}
                                  size="sm"
                                >
                                  <i
                                    className="mdi mdi-eye font-size-18"
                                    id="edittooltip"
                                  ></i>
                                </Button>
                                <Button
                                  color="danger"
                                  outline
                                  size="sm"
                                  onClick={() => {
                                    deletedFiles.push(fichier.fileName);
                                    setDeletedFiles([...deletedFiles]);
                                    // adherent.fichiers.splice(index, 1);
                                    // setAdherent(adherent);
                                  }}
                                >
                                  <i className="mdi mdi-delete font-size-18"></i>
                                </Button>
                              </div>
                            </div>
                          </Col>
                        ))}
                      {files.map((file, index) => (
                        <Col lg={4} md={6} sm={6} key={index}>
                          <div className="mt-4 document-item">
                            {/* <div style={{ flex: 2 }}> */}
                            <div className="document-preview">
                              {/* {file.file.type == "application/pdf" ? (
                                <img src={PDFIcon} />
                              ) : (
                                <img src={URL.createObjectURL(file.file)} />
                              )} */}
                            </div>
                            <div className="document-name">
                              {file.file.name}
                            </div>
                            <div className="document-type">
                              <Select
                                value={
                                  file.documentType &&
                                  DOCUMENT_TYPE_OPTIONS.find(
                                    dt => dt.value == file.documentType
                                  )
                                }
                                onChange={({ label, value }) => {
                                  file.documentType = value;
                                  files[index] = file;
                                  setFiles([...files]);
                                }}
                                options={DOCUMENT_TYPE_OPTIONS}
                                placeholder="Type de document"
                                classNamePrefix="select2-document-type"
                              />
                            </div>
                            {/* </div> */}
                            <div className="document-delete-button">
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                onClick={() => {
                                  files.splice(index, 1);
                                  setFiles([...files]);
                                }}
                              >
                                <i className="bx bx-trash font-size-18 align-middle me-2"></i>{" "}
                                Supprimer
                              </button>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <div className="mt-4">
                          <div>
                            <div className="input-group mb-3">
                              <Label
                                className="input-group-text input-file-label"
                                htmlFor="inputGroupFile01"
                              >
                                Ajouter un document
                              </Label>
                              <Input
                                style={{ display: "none" }}
                                type="file"
                                className="form-control input-file-label"
                                id="inputGroupFile01"
                                onChange={onFileChange}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Card>
                <CardBody>
                  <Row className="buttons-container">
                    <Col
                      xs={{ size: 4, offset: 2 }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: 5,
                      }}
                    >
                      <Button
                        color="light"
                        size="lg"
                        style={{ width: "100%" }}
                        onClick={history.goBack}
                      >
                        Annuler
                      </Button>
                    </Col>
                    {/* {isDraft && (
                      <Col
                        lg={4}
                        sm={4}
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginBottom: 5,
                        }}
                      >
                        <Button
                          color="secondary"
                          size="lg"
                          style={{ width: "100%" }}
                          onClick={() => {
                            onSave("en_attente", formik.values);
                          }}
                        >
                          Enregistrer en attente
                        </Button>
                      </Col>
                    )} */}
                    <Col
                      xs={4}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: 5,
                      }}
                    >
                      <Button
                        color="primary"
                        size="lg"
                        style={{ width: "100%" }}
                        // disabled={Object.keys(formik.errors).length}
                        onClick={() => {
                          // console.log(formik.errors);
                          formik.submitForm();
                        }}
                      >
                        Enregistrer
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Form>

          {!!displayedFile && (
            <Modal
              size="xl"
              id="view-file-modal"
              isOpen={viewModal && !!displayedFile}
              toggle={toggleViewModal}
            >
              <ModalBody>
                {displayedFile.contentType == "application/pdf" ? (
                  <iframe
                    width="100%"
                    height="100%"
                    src={
                      "data:application/pdf;base64, " +
                      encodeURI(displayedFile.file.data)
                    }
                  ></iframe>
                ) : (
                  <img
                    src={"data:image/png;base64," + displayedFile.file.data}
                  />
                )}
              </ModalBody>
            </Modal>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdherentsForm;
