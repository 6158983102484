import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Input, Row, Table } from "reactstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import dayjs from "dayjs";

const ActivitesSubList = ({
  activites,
  subscribe,
  search,
  searchActivites,
  onDesincrire,
  onValidate,
  adherentActivites,
  error,
}) => {
  const [text, setText] = useState("");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [activiteSelected, setActiviteSelected] = useState(null);
  const [listActivites, setListActivites] = useState([...activites]);
  const [selectedDays, setSelectedDays] = useState([]);

  const onInscrire = act => {
    if (act.avecCalendrier) {
      act.showDays = true;
      setListActivites([...listActivites]);

      let lSelectedDays =
        adherentActivites.find(elt => elt.id == act.id)?.selectedDays || [];
      setSelectedDays(lSelectedDays);
    } else {
      onValidate(act);
    }
  };

  const cancel = act => {
    act.showDays = false;
    setListActivites([...listActivites]);
    setSelectedDays([]);
  };

  useEffect(() => {
    setListActivites([...activites]);
  }, []);
  useEffect(() => {
    setListActivites([...activites]);
  }, [activites.length]);

  return (
    <div className="table-responsive">
      {!!search && (
        <div className="mb-2 " style={{ display: "flex" }}>
          <Col className="me-2" sm={6} xs={9}>
            <Input
              className="form-control"
              onChange={event => setText(event.target.value)}
              placeholder={"Activité ..."}
              value={text}
            />
          </Col>
          <Col sm={2} xs={2}>
            <Button
              color="light"
              className=""
              outline
              onClick={() => searchActivites(text)}
            >
              <i className="bx bx-search-alt search-icon" />
            </Button>
          </Col>
        </div>
      )}
      {!!error && (
        <Alert color="danger" role="alert">
          {error}
        </Alert>
      )}
      <div className="mb-2">
        {listActivites.map((act, k) => (
          <div key={"_act_" + k}>
            <Row className="align-items-center justify-content-between p-2">
              {/* <td style={{ width: "50px" }}>
                {act.img ? (
                  <img
                    src={images[act.img]}
                    className="rounded-circle avatar-xs"
                    alt=""
                  />
                ) : null}
              </td> */}
              <Col xs={6} className="font-size-14 m-0 text-dark">
                {act.nomActivite}
              </Col>
              <Col xs={6} className="text-center">
                {act.showDays ? (
                  <>
                    <Button
                      color="primary"
                      outline
                      className=""
                      onClick={() => {
                        act.selectedDays = selectedDays;
                        onValidate(act);
                        setSelectedDays([]);
                        act.showDays = false;
                        //setListActivites([...activiteSelected]);
                      }}
                    >
                      <i className="mdi mdi-clipboard-edit-outline act-icon"></i>{" "}
                      Valider
                    </Button>
                    <Button
                      color="danger"
                      outline
                      className="mx-2"
                      onClick={() => cancel(act)}
                    >
                      <i className="mdi mdi-clipboard-edit-outline act-icon"></i>{" "}
                      Annuler
                    </Button>
                  </>
                ) : subscribe ? (
                  <Button
                    color={act.estComplet ? "default" : "primary"}
                    outline={!act.estComplet}
                    className="btn-roundedd"
                    disabled={
                      act.estComplet ||
                      (adherentActivites &&
                        adherentActivites.find(elt => elt.id == act.id) &&
                        !act.avecCalendrier)
                    }
                    onClick={() => onInscrire(act)}
                  >
                    {act.estComplet ? (
                      <>
                        {" "}
                        <i className="fas fa-ban"></i> Complet
                      </>
                    ) : (
                      <>
                        {" "}
                        <i className="mdi mdi-clipboard-edit-outline act-icon"></i>{" "}
                        {adherentActivites.find(elt => elt.id == act.id)
                          ? act.avecCalendrier
                            ? "Modifier"
                            : "Déja inscrit"
                          : "Inscrire"}
                      </>
                    )}
                  </Button>
                ) : (
                  <>
                    {!!act.avecCalendrier && (
                      <Button
                        color="primary"
                        outline
                        className="me-2"
                        onClick={() => onInscrire(act)}
                      >
                        <i className="mdi mdi-clipboard-edit-outline act-icon"></i>{" "}
                        Modifier
                      </Button>
                    )}
                    <Button
                      color="danger"
                      outline
                      onClick={() => {
                        setConfirmAlert(true);
                        setActiviteSelected(act);
                      }}
                    >
                      <i className="mdi mdi-delete act-icon"></i> Retirer
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            {!!act.showDays && !!act.joursActivite && act.joursActivite.length && (
              <div className="">
                {act.joursActivite
                  .filter(j => !dayjs(j.date).isBefore(dayjs()))
                  .map((j, ind) => (
                    <Button
                      key={ind}
                      outline={!selectedDays.includes(j.date)}
                      className="m-1"
                      onClick={() => {
                        let days = [...selectedDays];
                        if (days.includes(j.date)) {
                          days = days.filter(d => d != j.date);
                        } else {
                          days.push(j.date);
                        }
                        setSelectedDays([...days]);
                      }}
                    >
                      {dayjs(j.date).format("DD/MM/YYYY")}
                    </Button>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {!!confirmAlert && (
        <SweetAlert
          title="Êtes-vous sûr?"
          warning
          showCancel
          confirmButtonText="Oui"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          cancelBtnText="Annuler"
          onConfirm={() => {
            setConfirmAlert(false);
            onDesincrire(activiteSelected);
            setActiviteSelected(null);
          }}
          onCancel={() => {
            setConfirmAlert(false);
            setActiviteSelected(null);
          }}
        ></SweetAlert>
      )}
    </div>
  );
};

ActivitesSubList.propTypes = {
  activites: PropTypes.array,
  subscribe: PropTypes.bool,
  search: PropTypes.bool,
  searchActivites: PropTypes.func,
  onDesincrire: PropTypes.func,
  onValidate: PropTypes.func,
  error: PropTypes.string,
  adherentActivites: PropTypes.array,
};

export default ActivitesSubList;
